<template>
    <footer class="footer">
        <section class="footer__section footer__navigation">
            <template v-if="!$fetchState.pending">
                <footer-list v-for="(list, index) in footerLists" :key="index" class="footer__list" :items="list.items" :title="list.title" />

                <uro-accordion class="footer__accordion" :items="footerLists">
                    <template #default="{ item }">
                        <footer-link v-for="(link, linkIndex) in item.items" :key="linkIndex" :item="link" />
                    </template>
                </uro-accordion>
            </template>
        </section>
        <section class="footer__section footer__logos">
            <img src="~/assets/img/logos/eau-full.svg" alt="European Association of Urology" width="429" height="42">

            <uro-socials class="footer__socials" />
        </section>
        <section class="footer__section footer__legal">
            <nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link>
            <nuxt-link to="/disclaimer">Disclaimer</nuxt-link>
        </section>
    </footer>
</template>

<script>
import UroSocials from '~/patterns/molecules/socials/socials.vue';
import FooterLink from '~/patterns/atoms/footer-link/footer-link.vue';
import FooterList from '~/patterns/molecules/footer-list/footer-list.vue';
import UroAccordion from '~/patterns/molecules/accordion/accordion.vue';

import footerQuery from '~/graphql/queries/globals/footer.graphql';

export default {
    components: {
        FooterList,
        FooterLink,
        UroAccordion,
        UroSocials
    },

    data() {
        return {
            footerLists: [
                {
                    title: 'Contact',
                    items: [
                        { text: 'EAU Central Office', type: 'text' },
                        { text: 'PO Box 30016', type: 'text' },
                        { text: 'NL-6803 AA ARNHEM', type: 'text' },
                        { text: 'The Netherlands', type: 'text' },
                        { text: 'Contact us', type: 'button', uri: '/contact' },
                    ]
                },
            ]
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(footerQuery);

        if (data) {
            const lists = [];
            data.footer.footer.forEach((column) => {
                lists.push({
                    title: column.blockTitle,
                    items: this.getItems(column)
                });
            });

            // Prepend lists to existing (hardcoded comes last)
            this.footerLists.unshift(...lists);
        }
    },

    methods: {
        getItems(column) {
            const items = [];
            column.children?.[0]?.lists?.forEach((item) => {
                const uri = this.getItemLink(item);
                items.push({
                    text: item.linkTitle,
                    type: uri ? 'link' : 'text',
                    uri
                });
            });

            return items;
        },

        getItemLink(item) {
            return item.linkInternal?.length ? `/${item.linkInternal[0].uri}` : item.linkExternal;
        },
    }
};
</script>

<style lang="less" src="./footer.less" />
