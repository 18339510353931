<template>
    <nav class="footer-list">
        <h5 v-if="title" class="footer-list__title">
            {{ title }}
        </h5>

        <ul class="footer-list__list">
            <li v-for="(item, index) in items" :key="index" class="footer-list__item">
                <footer-link :item="item" />
            </li>
        </ul>
    </nav>
</template>

<script>
import FooterLink from '~/patterns/atoms/footer-link/footer-link.vue';

export default {
    components: {
        FooterLink
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        items: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style lang="less" src="./footer-list.less" />
