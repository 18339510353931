<template>
    <main class="layout__main">
        <uro-error :error="error" />
    </main>
</template>

<script>
import UroError from '~/patterns/molecules/error.vue';

export default {
    components: {
        UroError
    },
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less"></style>
