import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _01d45a02 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _fb02f712 = () => interopDefault(import('../pages/guidelines/index.vue' /* webpackChunkName: "pages/guidelines/index" */))
const _00150087 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _319a164a = () => interopDefault(import('../pages/press-releases/index.vue' /* webpackChunkName: "pages/press-releases/index" */))
const _073d4c08 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _658e59ce = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a8183238 = () => interopDefault(import('../pages/undergraduates/index.vue' /* webpackChunkName: "pages/undergraduates/index" */))
const _240e0d28 = () => interopDefault(import('../pages/education-events/education.vue' /* webpackChunkName: "pages/education-events/education" */))
const _37de0149 = () => interopDefault(import('../pages/education-events/events.vue' /* webpackChunkName: "pages/education-events/events" */))
const _7812426a = () => interopDefault(import('../pages/guidelines/archive/index.vue' /* webpackChunkName: "pages/guidelines/archive/index" */))
const _11989471 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _765ba122 = () => interopDefault(import('../pages/guidelines/archive/_slug.vue' /* webpackChunkName: "pages/guidelines/archive/_slug" */))
const _49d63c0a = () => interopDefault(import('../pages/education-events/_slug.vue' /* webpackChunkName: "pages/education-events/_slug" */))
const _78c5287f = () => interopDefault(import('../patterns/organisms/guideline/pages/detail.vue' /* webpackChunkName: "" */))
const _a513dc74 = () => interopDefault(import('../patterns/organisms/guideline/pages/panel.vue' /* webpackChunkName: "" */))
const _aaa6fb2c = () => interopDefault(import('../patterns/organisms/guideline/pages/publications-appendices.vue' /* webpackChunkName: "" */))
const _418f5299 = () => interopDefault(import('../patterns/organisms/guideline/pages/related-content.vue' /* webpackChunkName: "" */))
const _10bc9302 = () => interopDefault(import('../patterns/organisms/guideline/pages/summary-of-changes.vue' /* webpackChunkName: "" */))
const _49738fe4 = () => interopDefault(import('../patterns/organisms/guideline/pages/summary-by-year.vue' /* webpackChunkName: "" */))
const _3a85150f = () => interopDefault(import('../patterns/organisms/guideline/pages/chapter.vue' /* webpackChunkName: "" */))
const _03434182 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _2d5afd5e = () => interopDefault(import('../pages/offices/_slug.vue' /* webpackChunkName: "pages/offices/_slug" */))
const _2fe37502 = () => interopDefault(import('../pages/press-releases/_slug.vue' /* webpackChunkName: "pages/press-releases/_slug" */))
const _2077af4c = () => interopDefault(import('../pages/sections/_slug.vue' /* webpackChunkName: "pages/sections/_slug" */))
const _146aa905 = () => interopDefault(import('../pages/tags/_slug.vue' /* webpackChunkName: "pages/tags/_slug" */))
const _b408ebe0 = () => interopDefault(import('../pages/topics/_slug.vue' /* webpackChunkName: "pages/topics/_slug" */))
const _ab8574c8 = () => interopDefault(import('../pages/undergraduates/_slug.vue' /* webpackChunkName: "pages/undergraduates/_slug" */))
const _2b860e04 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _01d45a02,
    name: "contact"
  }, {
    path: "/guidelines",
    component: _fb02f712,
    name: "guidelines"
  }, {
    path: "/news",
    component: _00150087,
    name: "news"
  }, {
    path: "/press-releases",
    component: _319a164a,
    name: "press-releases"
  }, {
    path: "/profile",
    component: _073d4c08,
    name: "profile"
  }, {
    path: "/search",
    component: _658e59ce,
    name: "search"
  }, {
    path: "/undergraduates",
    component: _a8183238,
    name: "undergraduates"
  }, {
    path: "/education-events/education",
    component: _240e0d28,
    name: "education-events-education"
  }, {
    path: "/education-events/events",
    component: _37de0149,
    name: "education-events-events"
  }, {
    path: "/guidelines/archive",
    component: _7812426a,
    name: "guidelines-archive"
  }, {
    path: "/",
    component: _11989471,
    name: "index"
  }, {
    path: "/guidelines/archive/:slug",
    component: _765ba122,
    name: "guidelines-archive-slug"
  }, {
    path: "/education-events/:slug?",
    component: _49d63c0a,
    name: "education-events-slug"
  }, {
    path: "/guidelines/:slug",
    component: _78c5287f,
    name: "guideline-slug",
    children: [{
      path: "panel",
      component: _a513dc74,
      name: "guideline-panel"
    }, {
      path: "publications-appendices",
      component: _aaa6fb2c,
      name: "guideline-publications-appendices"
    }, {
      path: "related-content",
      component: _418f5299,
      name: "related-content"
    }, {
      path: "summary-of-changes",
      component: _10bc9302,
      name: "guideline-summary",
      children: [{
        path: ":year",
        component: _49738fe4,
        name: "guideline-summaryByYear"
      }]
    }, {
      path: "chapter/:chapterSlug",
      component: _3a85150f,
      name: "guideline-chapterSlug"
    }]
  }, {
    path: "/news/:slug",
    component: _03434182,
    name: "news-slug"
  }, {
    path: "/offices/:slug?",
    component: _2d5afd5e,
    name: "offices-slug"
  }, {
    path: "/press-releases/:slug?",
    component: _2fe37502,
    name: "press-releases-slug"
  }, {
    path: "/sections/:slug?",
    component: _2077af4c,
    name: "sections-slug"
  }, {
    path: "/tags/:slug?",
    component: _146aa905,
    name: "tags-slug"
  }, {
    path: "/topics/:slug?",
    component: _b408ebe0,
    name: "topics-slug"
  }, {
    path: "/undergraduates/:slug",
    component: _ab8574c8,
    name: "undergraduates-slug"
  }, {
    path: "/sections/:slug/*",
    component: _2b860e04,
    name: "sections-child-slug"
  }, {
    path: "/offices/:slug/*",
    component: _2b860e04,
    name: "offices-child-slug"
  }, {
    path: "/*",
    component: _2b860e04,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
