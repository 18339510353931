<template>
    <form v-show="!(inNavigation && isSearchPage)" class="search-form" :class="{'search-form--navigation' : inNavigation}" @submit.prevent="doSearch">
        <uro-input
            v-model="searchQuery"
            name="search"
            placeholder="Search"
            type="search"
            :class="inputClass"
            :clearable="false"
        >
            <template #after>
                <transition name="fade" mode="out-in">
                    <button
                        key="search"
                        type="button"
                        class="button--clean"
                        aria-label="Search"
                        @click.prevent="doSearch"
                    >
                        <uro-icon icon="search" data-icon="search" />
                    </button>
                </transition>
            </template>
        </uro-input>
    </form>
</template>

<script>
import UroInput from '~/patterns/atoms/input/input.vue';

const SEARCH_PATH = '/search';

export default {
    components: {
        UroInput
    },
    props: {
        inNavigation: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            searchQuery: ''
        };
    },

    computed: {
        searchParam() {
            return this.$route.query.q;
        },
        isSearchPage() {
            return this.$route.path === SEARCH_PATH;
        },
        inputClass() {
            return this.inNavigation ? 'dn-input--rounded-secondary' : 'dn-input--rounded';
        }
    },

    watch: {
        searchParam() {
            this.searchQuery = this.searchParam;
        }
    },

    created() {
        this.searchQuery = this.searchParam;
    },

    methods: {
        doSearch() {
            this.$router.push({ path: SEARCH_PATH, query: { q: this.searchQuery } });
        }
    }
};
</script>

<style lang="less" src="./search-form.less" />
