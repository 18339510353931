<script>
export default {
    methods: {
        uriFromEntryFields(entry) {
            if (entry.linkInternal && entry.linkInternal.length) {
                const uri = entry.linkInternal[0]?.uri;
                return uri === '__home__' ? '/' : '/' + uri;
            }

            return entry.linkExternal;
        },

        checkIsExternal(url) {
            return url?.startsWith('http');
        }
    }
};
</script>
