<script>
import DnIcon from '@digitalnatives/icon';
import iconComponents from './icons';

export default {
    // The iconComponents below will lazily load *all* svg files in /assets/img/icons into this component
    // Use them by passing the correct icon prop to this component.
    components: iconComponents,
    extends: DnIcon,

    props: {
        hasStroke: {
            type: Boolean,
            default: true
        },
        hasFill: {
            type: Boolean,
            default: false
        },
        autoColor: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="less">
/** Override for items with explicit fill on svg but without the has-fill modifier -- give priority to what the SVG says */
.dn-icon > svg:not([fill='none']) {
    g, path, polygon, svg {
        fill: currentColor !important; /* stylelint-disable-line declaration-no-important */
    }
}

/** Override for items with explicit no stroke on svg but with has-stoke modifier as well -- give priority to what the SVG says */
.dn-icon--has-stroke > svg[stroke='none'] {
    g, path, polygon, svg {
        stroke: none !important; /* stylelint-disable-line declaration-no-important */
    }
}
</style>
