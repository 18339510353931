<template>
    <header class="uro-navigation" :data-sticky="scrollTop > 0">
        <div class="uro-navigation__container">
            <nuxt-link class="uro-navigation__logo" to="/" aria-label="Go to home" @click.native="menuToggled = false">
                <img alt="" src="~/assets/img/logos/eau.svg" width="82" height="33">
            </nuxt-link>

            <nav v-if="!$fetchState.pending && navigationLinks" class="uro-navigation__menu" :data-toggled="menuToggled">
                <template v-for="(item, index) in navigationLinks">
                    <template v-if="item.hasSubmenu">
                        <uro-navigation-link
                            :key="index"
                            element="div"
                            :title="menuTitles[index]"
                            :submenu="{ primaryMenu: item.primaryMenu, secondaryMenu: item.secondaryMenu }"
                            @closeMenu="menuToggled = false"
                        />
                    </template>
                    <template v-else>
                        <uro-navigation-link
                            :key="index"
                            :title="menuTitles[index]"
                            :to="getItemLink(item)"
                            @click.native="menuToggled = false"
                        />
                    </template>
                </template>

                <client-only>
                    <footer class="uro-navigation__menu__footer">
                        <template v-if="!$store.getters['user/isLoggedin']">
                            <uro-button
                                v-if="!disableLogin"
                                target="_self"
                                href="/auth"
                                element="a"
                                class="dn-button--label"
                                @click.native="menuToggled = false"
                            >
                                Log in
                            </uro-button>
                            <uro-button
                                element="nuxt-link"
                                to="/about/why-join-the-eau"
                                class="dn-button--label"
                                @click.native="menuToggled = false"
                            >
                                Become member
                            </uro-button>
                        </template>
                        <template v-else>
                            <nuxt-link
                                to="/profile"
                                :title="`You are logged in as ${$store.getters['user/user'].first_name}`"
                                class="uro-navigation__user"
                                aria-label="Go to your profile"
                                @click.native="menuToggled = false"
                            >
                                <uro-icon icon="person" /> {{ $store.getters['user/user'].first_name }}
                            </nuxt-link>
                        </template>
                        <uro-socials />
                    </footer>
                </client-only>
            </nav>

            <div class="uro-navigation__actions">
                <uro-search-form :in-navigation="true" />

                <client-only>
                    <div v-if="$store.getters['user/isLoggedin'] === false" class="uro-navigation__login">
                        <uro-navigation-link
                            v-if="!disableLogin"
                            target="_self"
                            href="/auth"
                            element="a"
                            class="navigation-link"
                            @click.native="menuToggled = false"
                        >
                            Log in
                        </uro-navigation-link>

                        <uro-button
                            element="nuxt-link"
                            to="/about/why-join-the-eau"
                            class="dn-buton--primary"
                            @click.native="menuToggled = false"
                        >
                            Become member
                        </uro-button>
                    </div>
                    <nuxt-link
                        v-else
                        to="/profile"
                        :title="`You are logged in as ${$store.getters['user/user'].first_name}`"
                        class="uro-navigation__user"
                        aria-label="Go to your profile"
                        @click.native="menuToggled = false"
                    >
                        <uro-icon icon="person" />
                    </nuxt-link>
                </client-only>

                <uro-hamburger :data-toggled="menuToggled" @click.native="menuToggled = !menuToggled" />
            </div>
        </div>
    </header>
</template>

<script>
import navigationQuery from '~/graphql/queries/globals/navigation.graphql';

import UroButton from '~/patterns/atoms/button/button.vue';
import UroHamburger from '~/patterns/atoms/hamburger/hamburger.vue';

import UroNavigationLink from '~/patterns/molecules/navigation-link/navigation-link.vue';
import UroSearchForm from '~/patterns/molecules/search-form/search-form.vue';
import UroSocials from '~/patterns/molecules/socials/socials.vue';

export default {
    components: {
        UroHamburger,
        UroButton,
        UroNavigationLink,
        UroSearchForm,
        UroSocials
    },

    props: {
        disableLogin: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            // This maps keys from the graphql query result to human-readable titles.
            navigationLinks: {},
            loggedIn: false,
            menuToggled: false,
            menuTitles: {
                guidelines: 'Guidelines',
                education: 'Education & Events',
                scientific: 'Science & Publications',
                about: 'About'
            },
            scrollTop: 0
        };
    },

    async fetch() {
        const response = await this.$gql.executeQuery(navigationQuery);
        if (response && response.data) {
            this.navigationLinks = response.data;
        }
    },

    head() {
        return {
            bodyAttrs: {
                'data-menu-open': this.menuToggled
            }
        };
    },

    watch: {
        menuToggled(state) {
            this.$emit('menuToggled', state);
        },
        $route(to, from) {
            this.menuToggled = false;
        }
    },

    async created() {
        await this.$store.dispatch('user/getUserInfo');
    },

    mounted() {
        document.getElementsByClassName('layout')[0].addEventListener('click', this.hideMenu, true);
        document.addEventListener('keydown', this.listenForEscape);
    },

    beforeDestroy() {
        document.getElementsByClassName('layout')[0].removeEventListener('click', this.hideMenu, true);
        document.removeEventListener('keydown', this.listenForEscape);
    },

    methods: {
        listenForEscape(e) {
            if (e.key === 'Escape') {
                this.menuToggled = false;
            }
        },

        hideMenu(e) {
            if (e?.target && e.target.className === 'layout') {
                this.menuToggled = false;
            }
        },

        getItemLink(item) {
            if (item.link?.length) {
                if (item.link[0].uri === '__home__') {
                    return '/';
                } else {
                    return '/' + item.link[0].uri;
                }
            }
            return '';
        },
        onScroll(e) {
            this.scrollTop = window.scrollY;
        }
    }
};
</script>

<style lang="less" src="./navigation.less" />
