import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

// Override the default message.
extend('required', {
    ...required,
    message: 'This field is required'
});

extend('email', {
    ...email,
    message: 'Please provide a correct e-mail address'
});
