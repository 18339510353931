<script>
export default {
    methods: {
        mapCmsIcon(iconName) {
            switch (iconName) {
            case 'onlineLearning':
                return 'student';
            case 'accreditation':
                return 'book-bookmark';
            case 'book':
            case 'journal':
                return 'books';
            case 'knife':
                return 'surgery';
            case 'lungs':
            case 'lung':
                return 'kidney';
            default:
                return iconName;
            }
        }
    }
};
</script>
