<template>
    <dn-button class="uro-button" v-bind="$attrs" v-on="$listeners">
        <slot />
    </dn-button>
</template>

<script>
import DnButton from '@digitalnatives/button';

export default {
    components: { DnButton }
};
</script>

<style src="./button.less" lang="less"></style>
