export default {
    searchQuery: ({ searchQuery }) => searchQuery,
    isSearching: ({ isSearching }) => isSearching,

    // Total highlights found (in all chapters combined)
    totalHighlights: ({ totalHighlights }) => totalHighlights,

    // Search information for all chapters, in the form of an array
    chapters: ({ chapters }) => chapters,

    // Currently active highlight's number
    currentHighlightNumber: ({ currentHighlightNumber }) => currentHighlightNumber,

    // Information on current guideline & chapter
    currentGuidelineId: ({ currentGuidelineId }) => currentGuidelineId,
    currentChapterSlug: ({ currentChapterSlug }) => currentChapterSlug,
    currentChapterContent: ({ currentChapterContent }) => currentChapterContent,

    /**
     * currentChapter
     *
     * Based on the currentChapterSlug, the chapter's information that's
     * currently activated is returned.
     */
    currentChapter(state, getters) {
        if (!getters.chapters.length) {
            return;
        }

        // Return the chapter based on the current slug
        if (getters.currentChapterSlug) {
            return getters.chapters.find(chapter => chapter.slug === getters.currentChapterSlug);
        }

        // Return first chapter if no slug is set
        return getters.chapters[0];
    },

    /**
     * Get the index of the current chapter, relative to other chapters in
     * the chapters array.
     */
    currentChapterIndex(state, getters) {
        if (!getters.chapters.length) {
            return;
        }

        // Return the current chapter's index based on the current slug
        if (getters.currentChapterSlug) {
            return getters.chapters.findIndex(chapter => chapter.slug === getters.currentChapterSlug);
        }

        // Return the current first chapter if no slug is set
        return 0;
    },

    /**
     * Returns how many search highlights were found for
     * the current chapter.
     */
    currentChapterHighlightCount(state, getters) {
        if (!getters.currentChapter) {
            return;
        }

        return getters.currentChapter.highlightCount;
    },

    /**
     * Get a chapter's search info based on its slug
     * @param {String} chapterSlug
     * @returns {Object} Chapter
     */
    getChapterBySlug: (state, getters) => (chapterSlug) => {
        return getters.chapters.find(chapter => chapter.slug === chapterSlug);
    },

    /**
     * Counts the number of highlights that are found in PREVIOUS chapters
     */
    highlightOffset(state, getters) {
        const previousChapters = getters.previousChaptersWithHighlights;

        return previousChapters.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.highlightCount;
        }, 0);
    },

    /**
     * Check if the highlight that's currently active is the FIRST
     * highlight within the chapter.
     */
    isFirstHighlightInChapter(state, getters) {
        return getters.currentHighlightNumber === getters.highlightOffset + 1;
    },

    /**
     * Check if the highlight that's currently active is the LAST
     * highlight within the chapter.
     */
    isLastHighlightInChapter(state, getters) {
        return getters.currentHighlightNumber === getters.highlightOffset + getters.currentChapter.highlightCount;
    },

    /**
     * Returns an array of chapters that contain search highlights.
     *
     * Note: The regular chapters getter returns ALL chapters.
     */
    chaptersWithHighlights(state, getters) {
        return getters.chapters.filter(chapter => chapter.highlightCount > 0);
    },

    /**
     * Returns an array of PREVIOUS chapters that contain highlights,
     * relative to the currently active chapter.
     */
    previousChaptersWithHighlights(state, getters) {
        const previousChapters = [];

        for (let index = 0; index < getters.currentChapterIndex; index++) {
            const chapter = getters.chapters[index];

            if (chapter.highlightCount > 0) {
                previousChapters.push(chapter);
            }
        }

        return previousChapters;
    },

    /**
     * Returns an array of NEXT chapters that contain highlights,
     * relative to the currently active chapter.
     */
    nextChaptersWithHighlights(state, getters) {
        const nextChapters = [];

        for (let index = getters.currentChapterIndex + 1; index < getters.chapters.length; index++) {
            const chapter = getters.chapters[index];

            if (chapter.highlightCount > 0) {
                nextChapters.push(chapter);
            }
        }

        return nextChapters;
    }
};
