'use strict';
import https from 'https';

export default function({ $axios, $config }, inject) {
    if ($config.apiToken) {
        $axios.setToken('Bearer ' + $config.apiToken);
    }

    // Ignore https errors on local enviroments.
    if (process.env.APP_ENV === 'development') {
        const agent = new https.Agent({
            rejectUnauthorized: false
        });

        $axios.onRequest((config) => {
            config.httpsAgent = agent;
        });
    }

    // Create an API client for non-GQL andpoints
    const api = $axios.create();

    // Set baseURL to something different
    api.setBaseURL($config.apiBaseURL);

    // Inject to context as $api
    inject('api', api);

    // Create client for all other calls
    const client = $axios.create();

    // Set baseURL to app url (ie localhost:3000)
    client.setBaseURL(process.env.APP_URL);

    // Inject to context as $client
    inject('client', client);
}
