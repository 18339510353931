export default {
    async logout({ getters, commit }) {
        if (getters.user) {
            await commit('set', null);
            window.location.href = '/logout';
        }
    },

    async getUserInfo({ getters, commit }) {
        if (getters.user) {
            return getters.user;
        }

        const { data: authCheck } = await this.$client.get('/auth/check');

        if (!authCheck.authenticated) {
            return;
        }

        try {
            const { data } = await this.$client.get('/auth/getUserInfo');

            if (!data || !data.authenticated) {
                throw new Error('Failed to refresh User');
            }

            commit('set', { user: { ...data.user } });
        } catch (e) {
            // Force user to login again on any error
            commit('set', null);
        }
    },
};
