import searchGuideline from '~/graphql/queries/guidelines/search.graphql';

export default {

    /**
     * Conduct a search query
     * Required params:
     * - guidelineId
     * - searchQuery
     * - chapterSlug
     *
     * Note: if the chapterSlug is empty, we assume we're working with
     * the first chapter.
     */
    async search({ commit, getters }, searchParams) {
        if (!searchParams.searchQuery || !searchParams.guidelineId) {
            return;
        }

        commit('isSearching', true);

        const { data } = await this.$gql.executeQuery(searchGuideline, {
            guidelineId: searchParams.guidelineId,
            search: searchParams.searchQuery,
            chapterSlug: searchParams.chapterSlug || ''
        });

        commit('isSearching', false);

        if (!data.results && !data.results[0]) {
            // No results
            return;
        }

        const searchResult = data.results[0];

        commit('currentGuidelineId', searchParams.guidelineId);
        commit('currentChapterSlug', searchParams.chapterSlug);
        commit('searchQuery', searchParams.searchQuery);
        commit('totalHighlights', searchResult.totalMatchesCount);

        // Format chapter meta data
        const chapters = searchResult.chapters.map((chapter) => {
            return {
                slug: chapter.slug,
                id: chapter.id,
                highlightCount: chapter.chapterMatchesCount
            };
        });
        commit('chapters', chapters);

        // Set result content
        const currentChapter = searchResult.chapters.find(chapter => chapter.slug === searchParams.chapterSlug);

        if (!searchParams.chapterSlug) {
            // When no slug is provided, we're working with the first chapter.
            commit('currentChapterContent', searchResult.chapters[0].chapterContent);

            // Go to the first highlight
            commit('currentHighlightNumber', 1);
        } else {
            // If, however, a slug is available: set that chapter's content
            commit('currentChapterContent', currentChapter.chapterContent);

            // Go to the first highlight of the chapter if not defined or out of range
            if (!getters.currentHighlightNumber || getters.currentHighlightNumber < getters.highlightOffset || getters.currentHighlightNumber > getters.highlightOffset + getters.currentChapter.highlightCount) {
                commit('currentHighlightNumber', getters.highlightOffset + 1);
            }
        }

        return searchResult;
    },

    /**
     * Reset all relevant search variables in the store
     */
    reset({ commit }) {
        commit('isSearching', false);
        commit('searchQuery', null);
        commit('totalHighlights', null);
        commit('currentHighlightNumber', 0);
        commit('chapters', []);
        commit('currentChapterContent', null);
    }
};
