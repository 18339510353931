export default ({ app }, inject) => {
    let initialized = false;
    let visible = false;

    function setup() {
        // Abort if we already added script to head
        const livechatScript = document.getElementById('livechat');
        if (livechatScript) {
            return;
        }

        // Add script tag to head
        const script = document.createElement('script');
        script.async = true;
        script.id = 'livechat';
        script.innerHTML = `
            window.__lc = window.__lc || {};
            window.__lc.license = 12060225;
            window.__lc.asyncInit = true;
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:'2.0',on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `;
        document.head.appendChild(script);
    }

    function init() {
        if (!initialized) {
            if (typeof LiveChatWidget !== 'undefined') {
                // eslint-disable-next-line no-undef
                LiveChatWidget.init();
                initialized = true;
            }
        }

        show();
    }

    function show() {
        if (visible) {
            return;
        }

        if (typeof LiveChatWidget !== 'undefined') {
            // eslint-disable-next-line no-undef
            LiveChatWidget.call('minimize');
            visible = true;
        }
    }

    function hide() {
        if (!visible) {
            return;
        }

        if (typeof LiveChatWidget !== 'undefined') {
            // eslint-disable-next-line no-undef
            LiveChatWidget.call('hide');
            visible = false;
        }
    }

    const livechat = {
        init,
        hide
    };

    inject('livechat', livechat);

    if (!process.server) {
        setup();
    }
};
