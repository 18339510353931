<template>
    <component
        :is="element"
        v-bind="$attrs"
        class="navigation-link"
        :class="{ 'navigation-link--subitem': isSub }"
        :data-has-icon="!!icon"
        :data-has-submenu="!!submenu"
        :target="isExternal ? 'blank' : 'self'"
        :rel="isExternal ? 'noopener noreferrer' : ''"
        :data-submenu-open="submenuVisible"
        v-on="$listeners"
        @click="() => submenu ? toggleSubmenu() : undefined"
        @mouseenter="() => submenu ? toggleHover(true) : undefined"
        @mouseleave="() => submenu ? toggleHover(false) : undefined"
    >
        <template v-if="icon">
            <div class="navigation-link__icon">
                <uro-icon :icon="icon" class="dn-icon--square" />
            </div>
        </template>

        <span class="navigation-link__title">
            <slot>
                {{ title }}
                <uro-icon v-if="isExternal" icon="arrow-up-right" />
                <uro-icon v-if="submenu" icon="chevron-down" />
            </slot>
        </span>

        <span v-if="subtitle || $slots.subtitle" class="navigation-link__subtitle">
            <slot name="subtitle">
                {{ subtitle }}
            </slot>
        </span>

        <transition name="fade">
            <uro-navigation-submenu
                v-if="submenu"
                v-show="submenuVisible"
                :primary-items="submenu.primaryMenu"
                :secondary-items="submenu.secondaryMenu"
                @click.native="closeMenu"
            />
        </transition>
    </component>
</template>

<script>
import NavigationSubmenu from '../navigation-submenu/navigation-submenu.vue';
import uriMixin from '~/mixins/uri.vue';

export default {
    name: 'UroNavigationLink',

    components: {
        'uro-navigation-submenu': NavigationSubmenu
    },

    mixins: [uriMixin],

    props: {
        element: {
            type: String,
            required: false,
            default: 'nuxt-link'
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        subtitle: {
            type: String,
            required: false,
            default: null
        },
        submenu: {
            type: Object,
            required: false,
            default: null
        },
        isSub: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            submenuVisible: false
        };
    },

    computed: {
        isExternal() {
            const destination = this.$attrs.href || this.$attrs.to;
            return this.checkIsExternal(destination);
        }
    },

    methods: {
        toggleSubmenu() {
            this.submenuVisible = !this.submenuVisible;
        },
        toggleHover(state) {
            if (window.innerWidth > 1440) {
                this.submenuVisible = state;
            }
        },
        closeMenu() {
            this.$emit('closeMenu');
        }
    }
};
</script>

<style lang="less" src="./navigation-link.less" />
