<template>
    <span class="footer-link">
        <template v-if="item.type === 'link' && item.uri">
            <component
                :is="checkIsExternal(item.uri) ? 'a' : 'nuxt-link'"
                :to="!checkIsExternal(item.uri) ? item.uri : undefined"
                :href="checkIsExternal(item.uri) ? item.uri : undefined"
                :target="checkIsExternal(item.uri) ? 'blank' : 'self'"
                :rel="checkIsExternal(item.uri) ? 'noopener noreferrer' : undefined"
            >
                {{ item.text }}
            </component>
        </template>

        <template v-else-if="item.type === 'button' && item.uri">
            <uro-button element="nuxt-link" :to="item.uri">{{ item.text }}</uro-button>
        </template>

        <template v-else>
            {{ item.text }}
        </template>
    </span>
</template>

<script>
import uriMixin from '~/mixins/uri.vue';
import UroButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        UroButton
    },

    mixins: [uriMixin],

    props: {
        item: {
            type: Object,
            required: true,
            default: null
        }
    }
};
</script>

<style lang="less" src="./footer-link.less" />
