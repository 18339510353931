export default () => ({
    topicSectionName: 'topics',
    liveTypesCategoryName: 'liveTypes',
    onDemandTypesCategoryName: 'onDemandTypes',
    locations: [
        { title: 'Online', slug: 'online' },
        { title: 'In-person', slug: 'in-person' }
    ],
    locationOnline: { title: 'Online', slug: 'online' }
});
