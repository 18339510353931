export default {
    searchQuery(state, searchQuery) {
        state.searchQuery = searchQuery;
    },

    isSearching(state, isSearching) {
        state.isSearching = isSearching;
    },

    currentGuidelineId(state, currentGuidelineId) {
        state.currentGuidelineId = currentGuidelineId;
    },

    currentChapterSlug(state, currentChapterSlug) {
        state.currentChapterSlug = currentChapterSlug;
    },

    currentChapterContent(state, currentChapterContent) {
        state.currentChapterContent = currentChapterContent;
    },

    totalHighlights(state, totalHighlights) {
        state.totalHighlights = totalHighlights;
    },

    chapters(state, chapters) {
        state.chapters = chapters;
    },

    currentHighlightNumber(state, currentHighlightNumber) {
        state.currentHighlightNumber = currentHighlightNumber;
    },

    /**
     * Bump the currentHighlightNumber to the next one in line.
     * Only if the last one wasn't reached yet.
     */
    nextHighlight(state) {
        if (state.currentHighlightNumber === state.totalHighlights) {
            return;
        }

        state.currentHighlightNumber = state.currentHighlightNumber + 1;
    },

    /**
     * Bump the currentHighlightNumber to the previous one in line.
     * Only if it's not the first one already.
     */
    previousHighlight(state) {
        if (state.currentHighlightNumber <= 1) {
            return;
        }

        state.currentHighlightNumber = state.currentHighlightNumber - 1;
    }
};
