<script>
export default {
    methods: {
        mapSeoMetaFromCraft(seo = {}, entry) {
            seo = this.setDefaults(seo, entry);

            if (!seo) {
                return {};
            }

            if (seo?.title && seo.title.length && !seo.title.includes('- Uroweb')) {
                seo.title += ' - Uroweb';
            }

            const metaData = {
                meta: [
                    ...this.getFbFields(seo),
                    ...this.getTwFields(seo),
                    this.getRobots(seo)
                ]
            };

            if (seo.title && seo.title.length) {
                metaData.title = seo.title;
            }

            if (seo.description && seo.description.length) {
                metaData.meta.push(
                    { hid: 'description', name: 'description', content: seo.description },
                );
            }

            if (this.getCanonical(seo)) {
                metaData.link = [
                    this.getCanonical(seo)
                ];
            }

            return metaData;
        },

        setDefaults(seo, entry) {
            if (!entry) {
                return seo;
            }

            // Set a default description based on introText or searchText if defined
            if (!seo.description || !seo.description.length) {
                if (entry.searchDescription) {
                    seo.description = entry.searchDescription;
                } else if (entry.introText) {
                    seo.description = entry.introText.replace(/(<([^>]+)>)/gi, '');
                } else if (entry.intro) {
                    seo.description = entry.intro.replace(/(<([^>]+)>)/gi, '');
                }
            }

            // Set a default image based on entry image, or fallback to static image instead
            if (!seo.featuredimage) {
                // Set featured image from header- or overview image
                if (entry.overviewImage?.[0]?.seo) {
                    seo.featuredimage = { urlLarge: entry.overviewImage?.[0]?.seo };
                } else if (entry.overviewImage?.[0]?.large) {
                    seo.featuredimage = { urlLarge: entry.overviewImage?.[0]?.large };
                } else if (entry.headerImage?.[0]?.seo) {
                    seo.featuredimage = { urlLarge: entry.headerImage?.[0]?.seo };
                } else if (entry.headerImage?.[0]?.large) {
                    seo.featuredimage = { urlLarge: entry.headerImage?.[0]?.large };
                } else {
                    // Set a generic image
                    seo.featuredimage = { urlLarge: this.$config.appURL + '/social.png' };
                }
            }
            return seo;
        },

        getFbFields(seo) {
            if (seo.social && seo.social.facebook) {
                return Object.keys(seo.social.facebook).map((key) => {
                    if (key === 'image' && seo.social.facebook[key]) {
                        return { hid: 'og:' + key, property: 'og:' + key, content: seo.social.facebook[key].url };
                    } else if (key === 'image' && seo.featuredimage) {
                        return { hid: 'og:' + key, property: 'og:' + key, content: seo.featuredimage.urlLarge };
                    }
                    if (seo.social.facebook[key] && seo.social.facebook[key].length) {
                        return { hid: 'og:' + key, property: 'og:' + key, content: seo.social.facebook[key] };
                    }
                    return undefined;
                }).filter(item => item);
            }
            return [];
        },

        getTwFields(seo) {
            if (seo.social && seo.social.twitter) {
                return Object.keys(seo.social.twitter).map((key) => {
                    if (key === 'image' && seo.social.twitter[key]) {
                        return { hid: 'twitter:' + key, name: 'twitter:' + key, content: seo.social.twitter[key].url };
                    } else if (key === 'image' && seo.featuredimage) {
                        return { hid: 'twitter:' + key, property: 'twitter:' + key, content: seo.featuredimage.urlLarge };
                    }
                    if (seo.social.twitter[key] && seo.social.twitter[key].length) {
                        return { hid: 'twitter:' + key, name: 'twitter:' + key, content: seo.social.twitter[key] };
                    }
                    return undefined;
                }).filter(item => item);
            }
            return [];
        },

        getRobots(seo) {
            let content = 'all';

            if (seo.advanced && seo.advanced.robots) {
                content = seo.advanced.robots.join(', ');
            }

            return { hid: 'robots', name: 'robots', content };
        },

        getCanonical(seo) {
            if (seo.advanced && seo.advanced.canonical) {
                return { rel: 'canonical', href: seo.advanced.canonical };
            }

            return false;
        }

    }
};
</script>
