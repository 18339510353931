<template>
    <component :is="element" v-bind="attributes" class="quicklink">
        <span class="quicklink__text"><slot /></span>
        <uro-icon class="quicklink__icon dn-icon--square" :icon="checkIsExternal(uri) ? 'arrow-up-right' : 'arrow-right'" />
    </component>
</template>

<script>
import uriMixin from '~/mixins/uri.vue';

export default {
    mixins: [uriMixin],

    props: {
        uri: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        element() {
            if (!this.uri) {
                return 'span';
            }
            if (this.checkIsExternal(this.uri)) {
                return 'a';
            }
            return 'nuxt-link';
        },

        attributes() {
            if (!this.uri) {
                return null;
            }
            if (this.checkIsExternal(this.uri)) {
                return {
                    href: this.uri,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                };
            }
            return {
                to: this.uri
            };
        }
    }
};
</script>

<style lang="less" src="./quicklink.less" />
