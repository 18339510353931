import createPersistedState from 'vuex-persistedstate';
import { addHours } from 'date-fns';
import * as Cookies from 'js-cookie';
import cookie from 'cookie';

export default ({ store, req }) => {
    createPersistedState({
        paths: ['user'],
        storage: {
            getItem: (key) => {
                if (process.server && req && req.headers && req.headers.cookie) {
                    const parsedCookies = cookie.parse(req.headers.cookie);
                    return parsedCookies[key];
                } else {
                    return Cookies.get(key);
                }
            },
            setItem: (key, value) => {
                // By default set a session cookie
                if (process.server && req && req.headers && req.headers.cookie) {
                    return cookie.serialize(key, value, { expires: addHours(new Date(), 1), secure: false });
                } else {
                    return Cookies.set(key, value, { expires: addHours(new Date(), 1), secure: false });
                }
            },
            removeItem: key => Cookies.remove(key)
        },
    })(store);
};
