<template>
    <main>
        <uro-section>
            <template #title>
                <h1 v-if="error && error.statusCode === 404">Page not found</h1>
                <h1 v-else>An error occurred</h1>
            </template>
        </uro-section>

        <template v-if="error.statusCode === 404">
            <p class="lead">
                This page could not be found. The link might be broken or the page moved.
            </p>
            <uro-section>
                <quicklinks-list
                    class="quicklinks-list--large"
                    :links="[
                        { text: 'Search through our website', uri: '/search' },
                        { text: 'Go back to the homepage', uri: '/' },
                        { text: 'Find help on the contact page', uri: '/contact' },
                    ]"
                />
            </uro-section>
        </template>

        <p v-else-if="error && error.message" class="lead">{{ error.message }}</p>
    </main>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import QuicklinksList from '~/patterns/molecules/quicklinks-list/quicklinks-list.vue';

import seoMeta from '~/mixins/seoMeta.vue';

export default {
    components: {
        UroSection,
        QuicklinksList
    },

    mixins: [seoMeta],

    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    },

    head() {
        if (this.error.statusCode === 404) {
            this.title = 'Page not found';
            this.description = 'The page you\'ve requested cannot be found';
        } else {
            this.title = 'An error occurred';
            this.description = this.error.message;
        }

        return this.mapSeoMetaFromCraft({
            title: this.title,
            description: this.description
        });
    },
};
</script>
