const legacyNewsSlugs = [
    '10th-cem-common-problems-in-office-urology-key-messages',
    '10th-cem-key-messages-on-hormonal-therapy-of-prostate-cancer',
    '10th-cem-key-messages-on-rehabilitation-strategies-after-curative-cancer-treatment',
    '10th-cem-studies-from-austria-poland-top-abstracts-awards',
    '10th-erus-meeting-to-present-results-of-robotic-urology-fellowship-curriculum-pilot',
    '10th-eurep-meeting-comes-to-a-close-in-prague',
    '10th-seem-active-surveillance-in-small-renal-masses',
    '10th-seem-best-poster-and-video-awards',
    '10th-seem-in-belgrade-turkey-tops-abstract-submissions',
    '10th-seem-new-developments-in-prostate-biopsy',
    '10th-seem-new-imaging-tools-in-superficial-bladder-cancer',
    '10th-seem-rationale-for-salvage-lnd-in-prostate-cancer',
    '10th-seem-uro-oncology-topics-win-nod-of-jurors-in-country-competition',
    '10th-seem-young-urologists-present-new-approaches-in-country-competition',
    '11th-central-european-meeting-prostate-conditions',
    '11th-erus-meeting-demonstrates-latest-in-robotic-technology',
    '11th-erus-unique-combination-of-live-surgery-and-robotic-training',
    '11th-esou-a-sharper-focus-on-the-treatment-of-elderly-patients',
    '11th-esou-features-extensive-programme-emphasises-the-role-of-scientific-debates',
    '12th-cem-key-messages-in-the-treatment-of-luts',
    '12th-cem-management-issues-in-renal-cell-cancer',
    '12th-esou-debating-approaches-to-improved-outcomes-in-onco-urological-treatment',
    '12th-esou-focus-on-multidisciplinary-teamwork-and-training',
    '12th-esou-meeting-discusses-personalised-medicine-and-the-future-of-onco-urology',
    '13th-cem-a-change-in-ctc-detection-and-the-prediction-of-crpc-treatment-outcome',
    '14th-cem-demonstrates-the-high-level-of-urological-research-in-central-europe',
    '14th-cem-latest-developments-in-kidney-cancer-care-and-research',
    '14th-cem-to-tackle-regional-challenges-new-trends-in-uro-oncology',
    '16th-eaun-meeting-education-cooperation-and-exchange-among-urology-nurses',
    '1st-eulis-postgraduate-workshop-on-urolithiasis-comes-to-vienna',
    '1st-international-eulis-eau-comprehensive-stone-workshop',
    '2019-call-for-membership-to-existing-european-reference-networks-erns-open-soon',
    '20th-esur-meeting-giving-research-an-international-dimension',
    '21918-2',
    '24497-2',
    '250th-patient-screened-in-eau-research-foundations-magnolia-trial',
    '26th-annual-eau-congress-2nd-prize-best-abstract-in-oncology',
    '28-february-rare-disease-day',
    '28th-congress-of-the-scandinavian-urologistsurology-nurses',
    '2nd-esffu-esgurs-esou-meeting-enterocystoplasty-in-children-with-genitourinary-abnormalities-is-safe-and-effective',
    '2nd-esu-asu-joint-course-in-manila-tackles-paediatric-urology-uro-oncology-issues',
    '2nd-esui-on-the-way-to-innovative-solutions-in-urological-imaging',
    '2nd-eulis-meeting-in-copenhagen-ushers-in-new-chairman',
    '2nd-eulis-meeting-towards-personalised-management-of-urolithiasis',
    '2nd-eulis-postgraduate-workshop-comes-to-budapest',
    '3758',
    '3769',
    '3954',
    '3rd-baltic-meeting-examines-challenges-in-uro-oncology-andrology',
    '3rd-emuc-142-abstracts-34-countries',
    '3rd-emuc-multidisciplinary-thought-provoking-interactive',
    '3rd-esgurs-meeting-hot-topics-on-reconstructive-surgery',
    '3rd-esui-meeting-insights-and-prospects-on-urological-imaging',
    '3rd-esui-meeting-standardized-nomenclature-for-imaging-in-urology-is-needed',
    '3rd-esui-section-meeting-advanced-imaging-for-diagnosis-and-treatment',
    '4174',
    '4176',
    '4691',
    '4692',
    '4th-emuc-abstracts-to-be-published-in-european-urology-supplements',
    '4th-emuc-best-abstract-posters-uk-study-on-bladder-cancer-tops-selection',
    '4th-emuc-diagnostic-issues-and-treatment-options-in-metastatic-prostate-cancer',
    '4th-emuc-in-barcelona-critical-appraisal-and-consensus-on-new-treatment-approaches-in-urological-cancers',
    '4th-neem-in-riga-key-messages-in-non-muscle-invasive-bladder-cancer-session',
    '4th-neem-in-riga-key-messages-on-prostate-cancer-treatment-issues',
    '4th-neem-in-riga-opening-session-tackles-key-issues-in-prostate-cancer',
    '4th-neem-in-riga-opens-tomorrow-key-uro-oncological-issues-top-agenda',
    '5th-emuc-to-examine-personalised-therapies-for-urological-cancers',
    '5-years-of-steps-the-way-forward',
    '6th-emuc-active-surveillance-in-pca-a-temporary-but-indispensable-solution',
    '6th-emuc-experts-examine-strategies-in-prostate-cancer-management',
    '6th-emuc-in-lisbon-common-goals-in-uro-oncology',
    '6th-emuc-issues-and-challenges-in-small-renal-masses',
    '6th-emuc-selected-oral-abstract-presentations-examine-new-diagnostics',
    '6th-emuc-updates-and-prospects-in-bladder-cancer',
    '6th-emuc-updates-on-locally-advanced-kidney-cancer',
    '6th-seem-istanbul-prostate-cancer-issues-re-examined-in-opening-session',
    '7th-seem-andrology-session-selected-key-messages',
    '7th-seem-poster-session-on-bladder-cancer-and-uretheral-surgery',
    '7th-seem-to-tackle-hot-issues-in-urological-cancers-andrology-and-paediatric-urology',
    '8th-esou-challenges-well-entrenched-approaches-in-pca-therapies',
    '8th-esou-meeting-bladder-cancer-session-key-messages',
    '8th-esou-meeting-renal-cancer-session-key-messages',
    '8th-seem-turkey-bulgaria-lead-abstract-race',
    '9th-esau-breaking-barriers-in-science-and-knowledge-sharing',
    '9th-esou-gene-therapy-shows-promise-in-non-muscle-invasive-bladder-cancer',
    '9th-esou-meeting-re-examines-prostate-cancer-issues-on-screening-biopsies-and-imaging',
    'accepting-donor-kidneys-with-stones-remains-a-thorny-issue',
    'access-now-living-textbook-on-infections',
    'a-challenger-in-davos-series-dr-axel-merseburger',
    'a-challenger-in-davos-series-dr-bogdan-geavlete',
    'a-complete-spectrum-of-clinical-laser-applications',
    'a-day-for-the-young-urologist-reporting-from-the-yau-junior-erus-programme-at-erus14',
    'a-first-eau-research-foundation-special-session',
    'african-urologists-face-chronic-challenges',
    'a-journal-is-more-than-its-impact-factor',
    'a-look-at-the-current-state-of-affairs-for-the-eaus-external-relations',
    'a-lot-that-i-achieved-in-life-was-thanks-to-the-eau',
    'amendment-to-eu-cancer-plan-encourages-eu-member-states-to-consider-early-detection-of-prostate-cancer',
    'american-society-of-clinical-oncology-endorses-eau-guideline-on-muscle-invasive-and-metastatic-bladder-cancer',
    'amsterdam-to-host-international-state-of-the-art-conference-on-prostate-and-kidney-cancers',
    'an-exclusive-interview-with-prof-maria-jose-ribal-caparros-new-chair-of-the-guidelines-office',
    'an-interview-with-dr-henk-van-der-poel-chairman-of-the-local-organising-committee-for-erus14',
    'an-interview-with-the-new-eaun-chair',
    'announcement-contribute-to-eu-policy-on-the-safety-of-meshes',
    'announcement-dr-michiel-sedelaar-appointed-chairman-of-eau-young-urologists-office',
    'announcement-nimbus-trial-ends-prematurely',
    'annual-congress-evidence-based-practice-changing-sessions-in-milan',
    'antibiotic-stewardship-a-crucial-task-to-counter-multidrug-resistant-bacteria',
    'antimicrobial-resistance-in-urology-joint-session-cautions-prudence',
    'application-open-chair-of-the-eau-guidelines-office',
    'application-open-for-4-eau-positions',
    'apply-before-1-january-to-join-canadian-tour-2012',
    'apply-now-ai-models-deep-learning-and-rwd-for-oncology-research-project',
    'a-profile-of-wellingtons-urological-nursing-services',
    'are-there-useable-molecular-markers-for-prostate-cancer',
    'are-you-presenting-at-this-years-eau-congress-in-paris',
    'are-you-the-one-the-yau-is-searching-for',
    'art-in-flexible-step-2-prepares-for-minimally-invasive-field',
    'attendees-entitlements',
    'a-view-on-the-challenges-in-egypts-public-healthcare-amid-political-turmoil',
    'avoid-fraudulent-eau19-websites',
    'avoid-fraudulent-eau20-websites',
    'a-year-after-an-interview-with-prof-ndow',
    'baltic16-examining-the-new-drug-landscape-in-crpc',
    'baltic16-expert-warns-of-drug-resistance-in-mcrpc-patients',
    'baltic-16-lithuania-estonia-studies-win-best-poster-awards',
    'baltic17-best-abstracts-winners-reflect-dynamic-work-in-region',
    'baltic-17-looks-into-best-practices-in-uro-oncology',
    'baltic17-treatment-strategies-in-urological-malignancies',
    'bca18-delivering-key-updates-in-bladder-cancer-management',
    'bca18-road-maps-in-personalising-bladder-cancer-treatment',
    'bca19-an-exciting-time-in-bladder-cancer-field',
    'bca-the-forgotten-tumour',
    'behind-the-scenes-of-the-eau-congress-technical-figures',
    'belgrade-researchers-dominate-best-abstract-winners-at-7th-seem',
    'be-repair-in-girls-leads-to-good-sexual-function-poor-fertility-and-body-image-in-adulthood',
    'best-posters-at-esut16-digitising-informed-consent-with-mhealth',
    'big-datas-crucial-role-in-transforming-healthcare-policies',
    'bladder-cancer-europe-2016-meeting-urgent-policy-action-needed',
    'boost-your-career-in-2011-apply-for-scholarship-this-winter',
    'bridging-communities-a-dynamic-pro-active-involvement-with-the-eaun',
    'bringing-together-scientists-and-surgeons-eulis-meeting-chairman-mr-noor-buchholz',
    'british-association-endorses-eau-guidelines-at-baus2015',
    'call-for-abstracts-esui-vision-award-2017',
    'call-for-abstracts-esui-vision-award-2018',
    'call-for-applicants-eau-section-office-chairman',
    'call-for-applications-adjunct-secretary-general-science',
    'call-for-applications-chairman-of-the-eau-membership-office',
    'call-for-applications-e4000-grant-for-research-in-renal-transplantation',
    'call-for-applications-eau-research-foundation-career-development-grant',
    'call-for-applications-eau-rf-unveils-new-seeding-grant',
    'call-for-applications-grant-for-research-in-renal-transplantation',
    'call-for-applications-scholarship-for-pioneer-covid-19-projects',
    'call-for-applications-two-editors-in-chief-for-eu-focus-and-eu-oncology',
    'call-for-applications-yau-searches-for-young-promising-talent',
    'call-for-applications-yuo-leadership-programme',
    'call-for-candidates-for-the-esui-vision-award',
    'call-for-candidates-for-the-esui-vision-award-2',
    'call-for-nominations-2015-dominique-chopin-distinguished-award',
    'call-for-nominations-2016-dominique-chopin-distinguished-award',
    'call-for-nominations-2016-dominique-chopin-distinguished-award-2',
    'call-for-nominations-dominique-chopin-distinguished-award',
    'call-for-proposals-to-use-biological-samples-collected-as-part-of-the-magnolia-study',
    'calling-senior-residents-for-the-usanz-trainee-week-2019',
    'calls-for-applications-secretary-general-elect-and-chair-of-the-young-urologists-office',
    'catto-receives-eau-crystal-matula-award',
    'c-dimopoulos-1934-2015-beloved-mentor-educator-and-urologist',
    'celebrating-the-history-and-culture-of-urology',
    'cem15-benefits-of-open-robot-assisted-radical-prostatectomy-are-equal',
    'cem15-concerted-efforts-by-doctors-needed-to-combat-antibiotic-resistance',
    'cem15-genetic-research-on-prostate-and-kidney-cancers-wins-award',
    'cem15-identifying-needs-of-young-urologists-in-europe',
    'cem15-prostate-and-kidney-cancer-studies-lead-research-awards',
    'cem15-surgical-limits-in-metastatic-kidney-cancer',
    'cem15-uro-oncology-dominates-presentations-in-young-urologists-competition',
    'cem16-looking-beyond-surgery-to-boost-urology',
    'cem16-robot-assisted-laparoscopy-to-outpace-robotic-technology',
    'cem16-romania-leads-best-abstracts-presentations-in-vienna',
    'cem16-romanian-study-identifies-two-snps-linked-to-pca',
    'cem17-young-urologists-show-best-work-in-well-attended-contest',
    'cem-2012-are-lasers-replacing-resectoscopes-in-bpe-surgery',
    'cem-2012-issues-in-luts-bpebph-examined-at-dresden-meeting',
    'cem-highlight-challenges-in-non-muscleinvasive-bladder-cancer',
    'cem-more-active-professional-exchange-among-young-urologists',
    'challenges-in-training-and-education',
    'challenge-the-experts-in-davos-the-winners-perspective',
    'challenge-what-you-know-with-two-new-e-courses',
    'chapple-assumes-secretary-general-post',
    'chemical-exposure-linked-to-sperm-defects-says-us-study',
    'china-eau-to-further-expand-collaborative-meetings',
    'christopher-griffith-wood-1963-2021',
    'chronic-pelvic-pain-guideline-development-identifying-the-best-evidence-for-clinical-practice',
    'clinical-use-of-biomarker-studies-needed',
    'clu-aims-to-expand-international-ties',
    'collaboration-across-europe-the-eau-meets-national-societies-meeting',
    'collaboration-is-crucial-in-stone-management',
    'come-find-us-at-aua2017',
    'committee-presents-candidates-for-secretary-general-elect-and-chair-of-the-guidelines-office',
    'congress-in-numbers-more-international-than-ever',
    'congress-on-history-of-urology-joins-forces-with-andrology-update-2011',
    'constant-anticholinergic-drug-use-among-elderly-linked-to-higher-dementia-risk',
    'covid-19-recommendations-by-eau-guidelines-panels',
    'covid19-resources-for-urologists',
    'credit-registry-report-2010-check-your-collected-credit-points',
    'cutting-for-the-stone-a-historical-perspective-on-the-rectovesical-approach',
    'czech-republic-romania-lead-in-cem17s-best-poster-presentations',
    'david-ralph-new-esgurs-chairman',
    'davos16-threat-of-urological-infections-at-challenge-the-experts',
    'davos-2011-at-the-peak-of-science',
    'davos-urological-challenge-award-for-alberto-breda',
    'deadline-alert-submit-your-research-or-nomination-for-prestigious-eau21-awards',
    'despite-high-prevalence-urinary-incontinence-is-still-very-much-a-taboo',
    'diet-exercise-play-a-role-in-reducing-stone-formation-risks',
    'difficulties-in-identifying-the-optimal-approach-in-kidney-cancer',
    'discovery-award-for-israeli-bio-chip-testing-technology',
    'docetaxel-as-a-vital-option-for-corticosteroid-refractory-prostate-cancer',
    'dont-forget-to-register-your-event-for-urology-week',
    'dont-miss-the-remaining-gpiu-study-dates-and-the-esiueau-prostate-biopsy-side-study',
    'do-we-need-to-continue-androgen-deprivation-therapy',
    'dr-a-goh-to-discuss-a-complex-robotic-operation-based-on-the-principles-of-open-surgery',
    'dr-alberto-briganti-joins-eau-scientific-congress-office',
    'dusseldorf-symposium-tackles-detection-and-treatment-of-early-pca',
    'dutch-expert-psa-screening-is-here-to-stay-despite-debate',
    'dutch-study-shows-additional-role-for-abiraterone-in-blocking-tumour-growth-in-castration-resistant-prostate-cancer',
    'early-detection-of-prostate-cancer-a-multidisciplinary-look',
    'easy-rider-pirtek-benelux-raises-money-and-awareness-for-prostate-cancer-through-international-bike-ride',
    'eau-10th-seem-returns-to-belgrade',
    'eau14-highlight-testosterone-replacement-in-older-men',
    'eau14-souvenir-session-urological-imaging-is-a-highly-evolving-field-but-approaches-should-change',
    'eau15-2nd-eso-pca-observatory-shows-perspectives-in-multidisciplinary-approach',
    'eau15-abrahamsson-bids-adieu-in-upbeat-opening-ceremony',
    'eau15-a-qa-with-prof-chibber-president-of-the-urological-society-of-india',
    'eau15-combination-of-pathology-and-research-in-prostate-cancer',
    'eau15-cracking-the-biomarker-market',
    'eau15-decision-making-challenges-in-china-and-the-world',
    'eau15-e-blus-exam-helps-to-standardise-laparoscopic-skill-levels',
    'eau15-exercise-caution-in-some',
    'eau15-future-of-targeted-prostate-cancer-treatment',
    'eau15-high-risk-pca-better-curative-therapy-remains-elusive',
    'eau15-issues-in-benign-prostatic-obstruction',
    'eau15-joint-eau-siu-session-highlights-advances-in-treatment-of-metastatic-kidney-cancer',
    'eau15-large-prostates-knife-or-laser',
    'eau15-late-breaking-news-standardised-data-sets-should-undergo-critical-review',
    'eau15-latest-data-from-two-trials-with-enzalutamide-show-its-efficacy',
    'eau15-maximising-minimally-invasive-surgery',
    'eau15-mcrpc-looks-at-novel-research',
    'eau15-measuring-and-improving-prostate-cancer-care',
    'eau15-patient-centred-approach-an-important-cultural-aspect-of-urology',
    'eau15-pausa-addresses-ongoing-challenges-in-african-urology',
    'eau15-personalised-medicine-clinical-trials-are-crucial',
    'eau15-residents-day-showing-young-specialists-the-eau-way',
    'eau15-robot-assisted-radical-cystectomy-still-not-the-new-standard',
    'eau15-state-of-the-art-lectures-present-latest-in-prostate-biopsy-and-bladder-obstruction',
    'eau15-the-spanish-angle-urological-problems-of-charles-ii-1661-1700',
    'eau15-trends-in-bladder-cancer-treatment',
    'eau15-value-and-limitations-of-petct-in-pca',
    'eau15-young-academic-urologists-represent-the-future-of-eau',
    'eau16-record-high-in-abstracts-submissions-for-munich',
    'eau17-activating-patients-is-key-to-enhanced-recovery',
    'eau17-breakthroughs-in-andrology',
    'eau17-eau-cau-meeting-delivers-new-approaches',
    'eau17-esut-erus-eulis-pull-out-all-the-stops-in-live-surgery-session',
    'eau17-evaluating-the-effectiveness-of-treatment-for-clinically-localised-prostate-cancer',
    'eau17-expert-challenges-expert',
    'eau17-family-history-a-major-risk-factor-for-prostate-cancer-development',
    'eau17-highlights-from-the-souvenir-session',
    'eau17-hurdles-in-managing-renal-cancer-sleepless-nights-session-offers-insights-on-legal-pitfalls',
    'eau17-inaccurate-adverse-event-reporting-may-lead-to-improper-treatment-of-sexually-active-men-with-bph',
    'eau17-pca-debates-promis-and-heredity-at-fifth-plenary-session',
    'eau17-plenary-session-examines-complex-neuro-urological-cases',
    'eau17-sir-bruce-keogh-professional-links-are-crucial-to-counter-challenges',
    'eau-17-stem-cells-shown-to-restore-erection-capability-in-men-with-erectile-dysfunction',
    'eau17-stones-emerging-data-and-emergency-treatment',
    'eau17-the-ergonomic-consequences-of-urological-surgery',
    'eau17-thematic-session-explores-personalised-medicine-in-prostate-cancer',
    'eau18-scientific-programme-from-precision-medicine-to-future-prospects',
    'eau19-qa-with-arnulf-stenzl-chair-of-the-scientific-congress-office',
    'eau-2015-opening-ceremony-highlights-urologists-together',
    'eau20-immediately-beats-eau19s-record-for-most-abstract-submissions',
    'eau20-theme-week-topical-summer-evening-sessions',
    'eau20-will-still-provide-state-of-the-art-urological-science',
    'eau21-65-abstract-sessions-featuring-every-single-accepted-abstract',
    'eau21-general-assembly-new-eau-board-and-executive-members',
    'eau21-opens-with-full-day-semi-live-surgery-and-urology-beyond-europe-sessions',
    'eau21-will-be-a-virtual-congress',
    'eau22-postponed-new-dates-soon',
    'eau-4th-north-eastern-european-meeting',
    'eau-and-partners-submit-joint-letter-to-ask-for-continued-support-from-meps-on-prostate-cancer',
    'eau-annual-congress-prostate-cancer-urothelial-tumours-top-abstract-submissions',
    'eau-awards-mark-high-quality-urology-research-in-europe-and-beyond',
    'eau-baltic-2015-best-abstract-presentation-winners',
    'eau-baltic-meeting-2015-trends-in-prostate-and-bladder-cancer-treatments',
    'eau-baltic-meeting-2015-urological-progress-in-the-baltics',
    'eau-chair-sco',
    'eau-co-chairs-prestigious-european-personalised-health-congress',
    'eau-congress-abstract-selection-highlights-diversity',
    'eau-congress-a-pioneer-in-complying-to-ethical-rules-on-live-surgeries',
    'eau-congress-a-school-of-thought',
    'eau-ebu-streamline-certification-process-for-training-centres',
    'eau-education-online-e-platform-receives-eaccme-accreditation',
    'eau-education-online-launches-new-cme-course',
    'eau-endorses-european-code-of-cancer-practice-the-code',
    'eau-euga-issue-consensus-statement-on-implant-use-in-treating-pop-and-sui',
    'eau-experts-join-5th-iffu-in-beijing',
    'eau-focused-strategy-to-encourage-timely-detection-pca',
    'eau-guarding-the-quality-of-science-and-education',
    'eau-guidelines-2020-now-available-online',
    'eau-guidelines-continuous-quality-improvement-programme',
    'eau-guidelines-going-for-the-evidence-base-identifying-the-literature-for-the-cpp-panel',
    'eau-highlights-the-need-for-a-clear-and-enabling-eu-legal-framework-for-researchers-in-the-european-health-data-space',
    'eau-history-office-new-projects-call-for-collaboration',
    'eau-history-office-translates-and-publishes-newly-discovered-vesalius-consilium',
    'eau-icud-consensus-meeting-strategies-in-immunotherapy-and-chemotherapy-of-pca',
    'eau-icud-consensus-meeting-to-open-emuc-2014',
    'eau-icud-consultation-sparks-debate',
    'eau-icud-sessions-aging-population-key-factor-that-impacts-prevalence',
    'eau-joins-bladder-cancer-europe-roundtable-meeting-in-brussels',
    'eau-kicks-off-its-first-ever-virtual-annual-congress',
    'eau-launches-search-for-new-patient-information-chairman',
    'eau-launches-virtual-european-museum-of-urology',
    'eau-membership-now-extends-to-medical-students',
    'eau-membership-office-an-update',
    'eau-mourns-passing-of-prof-john-fitzpatrick',
    'eaun-addresses-mens-health-issues',
    'eau-national-societies-meeting-2018-emergent-challenges-in-europe',
    'eau-national-societies-to-align-strategies-on-the-development-of-urology-in-europe',
    'eau-new-year-resolutions-good-will-and-the-development-of-urology-in-africa',
    'eaun-fellowship-programme-report',
    'eau-patient-information-amid-diverse-sources-providing-facts-can-be-a-challenge',
    'eau-patient-information-covid-19-call-for-action',
    'eau-patient-information-initiative-expands-new-translations-online',
    'eau-patient-information-launches-extensive-materials-on-prostate-cancer',
    'eau-patient-information-now-available-in-polish-and-albanian',
    'eau-patient-information-now-available-in-spanish-and-greek',
    'eau-patient-information-on-bpe-contributors-comment-on-project',
    'eau-patient-information-on-urinary-incontinence-helping-break-the-taboo',
    'eau-pausa-session-challenges-and-advances-in-africa',
    'eau-pi-names-eamonn-rogers-as-new-chairman',
    'eau-presents-at-the-cau-annual-meeting-understanding-the-side-effects-of-adt',
    'eau-provides-support-to-unique-training-course-in-romania',
    'eau-publications-2011-pick-up-your-copies-at-the-congress',
    'eau-pushes-for-the-inclusion-of-early-detection-of-prostate-cancer-in-the-eu-cancer-plan',
    'eau-puts-urological-care-on-europes-political-agenda',
    'eau-represented-and-honoured-at-the-36th-siu-congress',
    'eau-research-foundation-career-development-grant-application',
    'eau-research-foundation-explores-new-areas-for-collaboration-and-trials',
    'eau-research-foundation-grants-two-eau-researchers-with-the-prestigious-eau-rf-seeding-grant',
    'eau-research-foundation-offers-researchers-new-scholarship-and-publication-opportunities',
    'eau-research-foundation-strategic-plans-gain-momentum',
    'eau-rf-announces-prime-study-prostate-imaging-using-mri-contrast-enhancement',
    'eau-rf-career-development-programme-study-on-pca-stem-cell-characterisation-reports-initial-gains',
    'eau-rf-seeding-grants-call-for-applications',
    'eau-rf-studies-facts-and-figures-now-online',
    'eau-robotic-urology-section-erus-guidelines-during-covid-19-emergency',
    'eau-rsu-eye-collaborative-projects-and-closer-ties',
    'eaus-active-role-in-beijings-urology-forum-and-masterclasses',
    'eau-statement-on-brexit-referendum',
    'eau-supports-urology-training-in-tanzania-to-enhance-residents-knowledge-and-understanding-of-modern-urology',
    'eau-symposium-at-eusc-2016',
    'eau-to-boost-ties-with-partners',
    'eau-tv-challenging-the-evidence-evidence-based-medicine',
    'eau-tv-eau16-live-surgery-delegate-reactions',
    'eau-tv-eau16-opening-ceremony-highlights',
    'eau-tv-highlighting-posters-from-eau16',
    'eau-tv-history-in-the-making',
    'eau-tv-interview-with-francesco-montorsi-adjunct-secretary-general-eau',
    'eau-tv-new-options-for-prostate-cancer-patients',
    'eau-tv-robotic-surgery-and-advanced-prostate-cancer-at-eau21',
    'eau-tv-simply-the-best-eau-award-winning-posters',
    'eau-tv-statins-and-prostate-cancer-mortality',
    'eau-tv-the-best-of-bladder-cancer-and-renal-cell-carcinoma-at-eau21',
    'eau-tv-the-best-on-prostate-cancer-and-incontinence-andrology-at-eau21',
    'eau-tv-the-latest-in-prostate-cancer',
    'eau-tv-the-political-ambitions-of-the-eau-by-prof-chris-chapple',
    'eau-tv-urinary-tract-infection-the-challenges-and-solutions',
    'eau-tv-urologists-from-far-and-wide-at-eau16-urology-beyond-europe',
    'eau-uniquely-placed-to-help-european-urologists-ambitions',
    'eau-welcomes-new-section-addresses-robotics-in-urology',
    'ebu-eau-host-centre-certification-a-win-win-for-the-scholar-and-the-centre',
    'ebu-recertifies-st-gallen-hospitals-urology-department',
    'ecco-re-elects-van-poppel-as-board-director',
    'editorial-by-keith-parsons',
    'education-activities-to-meet-future-needs-and-challenges',
    'efforts-needed-keep-pca-in-public-agenda',
    'emerging-treatment-options-in-pca-examined-in-8th-esou-meeting',
    'emphasis-on-personalised-treatment-during-1st-international-euog-state-of-the-art-conference-on-kidney-and-prostate-cancer',
    'emuc13-exercise-training-is-effective-as-prehabilitation-before-surgery-in-an-elderly-population',
    'emuc14-interview-surgery-remains-the-gold-standard-for-high-risk-kidney-cancer',
    'emuc14-multidisciplinary-approach-most-suited-to-tackle-future-uro-oncological-challenges',
    'emuc15-best-unmoderated-posters-in-bladder-and-kidney-cancers',
    'emuc15-bladder-cancer-updates-on-systemic-treatments',
    'emuc15-challenges-and-developments-in-urological-cancers',
    'emuc15-challenges-in-kidney-cancers',
    'emuc15-examining-news-issues-and-challenges-in-prostate-cancer',
    'emuc15-experts-stress-key-role-of-collaborative-work-data-sharing-to-boost-bladder-cancer-research',
    'emuc15-highlights',
    'emuc15-study-shows-prostate-cancer-screening-under-age-of-55-may-be-of-limited-value',
    'emuc15-systemic-treatment-for-metastatic-kidney-cancer',
    'emuc16-consequences-of-pelvic-radiotherapy',
    'emuc16-experts-tackle-combined-prostate-cancer-therapies',
    'emuc16-imaging-and-the-treatment-landscape-for-recurrent-pca',
    'emuc16-kidney-cancer-from-diagnosis',
    'emuc16-national-cancer-prevention-plans-need-a-boost',
    'emuc16-refined-patient-selection-is-crucial-to-improve-impact-of-chemotherapy-for-bladder-cancer-patients',
    'emuc16-re-thinking-active-surveillance-strategies-in-pca',
    'emuc17-improving-reporting-systems-defining-selection-criteria-in-pca-diagnosis',
    'emuc17-using-biomarkers-in-decision-making-for-rcc-treatment',
    'emuc17-weighing-the-gains-and-drawbacks-of-modern-pca-treatment-options',
    'emuc18-crucial-mdt-work-in-urological-cancers',
    'emuc18-finding-durable-solutions-in-prostate-cancer-management',
    'emuc18-updates-on-notable-trials',
    'emuc19-aramis-trial-likely-to-change-guidelines-recommendations',
    'emuc19-hot-courses-highlight-mri-reading-and-fusion-biopsy',
    'emuc19-what-to-do-when-faced-with-a-nightmare-pca-case',
    'emuc20-highlights-in-gu-cancers',
    'emuc20-is-now-a-virtual-congress',
    'emuc-full-day-esu-medical-oncology-course-on-genitourinary-cancer',
    'emuemuc16-trends-in-genitourinary-pathology-and-impact-on-clinical-practice',
    'endoscope-replica-presented-to-musee-dhistoire-de-la-medecine',
    'enduring-covid-new-eau-leadership-masterclass',
    'epad17-eu-politicians-experts-and-patients-commit-to-advancement-of-pca-care',
    'epad19-european-psa-screening-programme-is-on-its-way-part-2',
    'epad-2019-the-current-status-of-prostate-cancer-screening-in-eu',
    'epad20-a-landmark-for-early-detection-and-awareness-raising-of-prostate-cancer',
    'epad20-prostate-cancer-on-the-radar-for-inclusion-in-the-eu-cancer-plan',
    'ernst-j-zingg-1931-2016',
    'erus14-young-urologists-most-likely-to-achieve-high-marks-in-robotic-hands-on-training-course',
    'erus15-junior-erus-young-academic-urologists-meeting',
    'erus15-the-first-day-of-live-surgery',
    'erus15-the-local-angle-with-dr-jose-pereira-arias',
    'erus16-improving-post-rarp-erectile-dysfunction',
    'erus16-taking-robotic-urology-to-the-next-level',
    'erus17-robotic-section-attracts-both-young-and-experienced-urologists',
    'erus18-a-push-for-enhanced-patient-recovery-programme',
    'erus18-inaugural-john-wickham-award-goes-to-claude-abbou',
    'erus-2014-shifting-roles-for-nurses-and-the-importance-of-certification',
    'erus-2015-in-bilbao-now-accepting-abstracts-on-robotic-urology',
    'erus-drus20-day-1-virtual-robots',
    'erus-drus20-meeting-attracts-new-global-audience',
    'esau17-approaching-male-fertility-problems-as-a-sign-of-more-general-disease',
    'esffu-esgurs-events-examine-reconstructive-urology-issues',
    'esffu-positive-developments-high-expectations',
    'esgurs-welcomes-new-chairman',
    'esiu-alert-pan-resistant-bacteria-in-urinary-tract-infections',
    'eso-conference-tackles-thorny-prostate-cancer-issues',
    'eso-meeting-in-amsterdam-to-explore-the-limits-of-active-surveillance',
    'esou16-day-1-the-role-of-mri-in-pca-diagnosis-and-active-surveillance',
    'esou16-day-2-working-together-and-communicating',
    'esou16-highlights-from-the-scientific-programme',
    'esou17-an-interview-with-the-local-organiser',
    'esou17-game-changer-ahead-in-lymph-node-staging-detection',
    'esou19-aims-to-strengthen-onco-urology-global-community',
    'esou20-to-unveil-gu-fundamentals-in-dublin',
    'esou21-virtual-onco-urology-updates-and-innovations-you-need-to-know',
    'esou-debating-the-best-approach-to-radical-prostatectomy-and-cystectomy',
    'esou-goes-forward-with-steps-programme',
    'esou-zoran-culig-on-tmprsserg-fusion-and-short-androgen-receptors',
    'estu-eyes-new-developments-in-transplantation',
    'estu-to-grant-e4000-for-research-in-renal-transplantation',
    'esu-course-at-the-106th-national-congress-of-the-french-association-of-urology',
    'esu-course-imparts-tips-and-tricks-in-challenging-surgeries',
    'esu-esffu-masterclass-to-enrich-know-how-in-female-and-functional-urology',
    'esui15-ultrasound-in-urology-emerging-trends',
    'esui16-role-of-mri-needs-further-validation',
    'esui18-prospects-and-impact-of-emerging-imaging-technologies',
    'esui19-delivers-latest-mri-essentials',
    'esui19-psma-pet-updates-on-rationale-use-rgs-and-tracers',
    'esui19-take-part-in-the-evolution-of-urological-imaging',
    'esui-2012-understanding-the-role-of-imaging-in-urology',
    'esui-goes-to-barcelona-is-pet-a-game-changer-in-pca-management',
    'esu-introduces-new-kidney-transplant-masterclass',
    'esu-introduces-new-mhspc-e-course',
    'esu-introduces-new-urolithiasis-e-course-in-collaboration-with-eulis',
    'esui-vision-award-submit-your-nominations-today',
    'esu-online-the-umbrella-for-eaus-educational-activities-in-times-of-remote-learning',
    'esuo-redefines-its-name-and-mission-to-increase-appeal',
    'esu-programme-trends-in-science-and-practice',
    'esur16-stimulating-the-discussion-between-researchers-and-clinicians',
    'esur18-call-for-nominations',
    'esur18-convenes-top-experts-in-urological-research',
    'esur-2014-an-interview-with-prof-ellen-zwarthoff',
    'esur-brings-together-best-and-brightest-minds-in-european-urology-research',
    'esur-sbur15-experimental-and-translational-uro-oncology-andrology-and-tissue-engineering',
    'esur-sbur-15-fostering-exchange-in-urological-research',
    'esur-sbur15-offers-state-of-the-art-scientific-programme-for-an-international-audience',
    'esur-to-convene-in-dresden-collaborate-with-dgu',
    'esut16-day-1-tech-and-live-surgery',
    'esut16-in-athens-the-latest-in-technology-and-training',
    'esut16-the-latest-in-technology-and-surgical-technique',
    'esut18-iae',
    'esut-live-surgery-navigated-video-assisted-surgery',
    'esut-live-surgery-watch-cases-online',
    'esu-to-launch-or-workshops',
    'esu-training-and-research-group-reaching-new-heights-through-collaboration',
    'esu-weill-cornell-masterclass-in-general-urology-bridging-the-knowledge-gap',
    'eu-acme-steps-into-the-online-future',
    'eulis15-awards-best-research-and-clinical-work',
    'eulis15-comparing-the-pros-and-cons-of-pcnl-techniques',
    'eulis19-to-feature-updates-paramount-to-clinical-practice',
    'eulis-blog-2nd-technology-and-training-in-endourology-course-in-torino',
    'eulis-has-international-scope-eulis-chairman-prof-palle-osther',
    'eulis-members-awarded-lifetime-achievement-honorary-membership',
    'eulis-session-stresses-diversity-of-stone-disease',
    'eulis-to-take-part-in-5th-meeting-of-endourology-and-minimally-invasive-surgery-in-sofia',
    'eu-must-do-more-on-prostate-cancer',
    'eurep-inspiring-careers-in-urology',
    'eurf-looks-for-research-investigators-from-basic-urology-research-laboratories',
    'eurogen-is-approved-by-the-european-commission',
    'european-board-of-urology-welcomes-new-president',
    'european-commission-recruits-experts-for-evaluation-medical-devices',
    'european-parliaments-special-committee-on-beating-cancer-tackles-early-detection-of-prostate-cancer',
    'european-prostate-cancer-centres-eyes-network-of-top-notched-care-centres',
    'european-prostate-cancer-experts-re-affirm-psas-benefits-for-certain-men',
    'european-reference-networks-900-medical-teams-to-connect-across-europe-for-the-benefit-of-patients',
    'european-survey-shows-alarmingly-low-awareness-of-erectile-dysfunction-majority-does-not-know-what-it-is',
    'european-urology-lets-get-to-know-each-other',
    'european-urology-today-manuscript-submission',
    'european-views-on-psa-screening-debate',
    'europes-beating-cancer-plan-brings-hope-to-pca-patients',
    'eusp-aims-to-attract-best-researchers-from-across-europe',
    'eusp-office-is-looking-for-new-board-members',
    'eusp-programmes-to-take-place-in-home-countries',
    'eusp-report-unique-opportunities-in-budapest',
    'eusp-scholarship-a-catalyst-to-acquiring-my-phd',
    'evolution-luts-bph-registry-from-the-eau-rf',
    'evolving-with-a-stronger-vision-search-and-nomination-committee-selects-seven-nominated-new-eau-board-members',
    'examining-the-near-future-of-uro-technology',
    'exhibit-of-the-month-the-civiale-set',
    'exhibit-of-the-month-the-lewis-cystometer',
    'exhibit-of-the-month-the-weiss-lithotriptor',
    'expert-challenges-the-expert-thematic-session-looks-at-nerve-sparing-nephrectomy-and-laparoscopic-partial-nephrectomy',
    'experts-appraise-benefits-of-cystectomy-chemo-radiation',
    'experts-optimistic-of-advances-in-treating-gu-cancers',
    'experts-tackle-issues-in-prostate-cancer-diagnostics-and-treatment',
    'experts-to-examine-optimal-treatment-strategies-for-kidney-cancer',
    'father-of-ureteroscopy-to-present-at-urobestt-2020',
    'female-and-functional-urology-theory-practice-and-innovation',
    'first-esu-course-in-pristina-kosovo',
    'first-joint-eau-siu-session-focusses-on-urothelial-carcinoma',
    'flashback-congress-visitors-express-enthusiasm-for-new-training-methods',
    'focus-on-regional-trends-eau-7th-south-eastern-european-meeting-seem',
    'focus-on-training-opportunities-highlighted-in-eau-usanz-meeting',
    'focus-on-young-urologists-during-9th-south-eastern-european-meeting',
    'follow-esuts-blog-on-uroweb',
    'food-for-thought-ethics-in-urology',
    'four-countries-sign-en-bloc-agreement',
    'four-new-scholarship-positions-available-in-new-eusp-collaboration-with-reprounion',
    'free-for-eau-members-masterclass-on-the-making-of-modern-medicine',
    'free-for-members-a-new-history-office-publication',
    'free-registration-for-the-eau-congress-in-paris',
    'free-week-long-course-the-2014-esu-weill-cornell-masterclass-in-general-urology',
    'free-week-long-course-the-2014-esu-weill-cornell-masterclass-in-general-urology-2',
    'friday-congress-highlight-what-do-we-know-about-non-pca3-urinary-biomarkers',
    'from-basic-science-to-the-clinic',
    'full-text-abstracts-now-online-for-members',
    'fundamentals-on-research-strategies',
    'future-of-urology-discussed-at-annual-eau-national-societies-meeting',
    'gambias-horizons-project-takes-off',
    'general-assembly-approves-election-of-chapple-to-secretary-general-elect',
    'general-assembly-elects-rassweiler-and-van-kerrebroeck',
    'general-assembly-new-initiatives-and-increasing-collaboration',
    'genetics-age-and-ethnicity-are-risk-factors-in-prostate-cancer-say-experts',
    'genitourinary-tb-incidence-in-czech-republic-drops-in-last-eight-years',
    'genomics-drug-sequencing-key-areas-in-high-risk-prostate-cancer',
    'global-philanthropic-committee-calls-for-funding-request-applications',
    'gpiu-deadline-extended',
    'gpiu-study-representatives',
    'grasp-the-basics-of-laparoscopy-follow-a-course-online',
    'green-light-for-the-eau-policy-office-prof-hein-van-poppel-appointed-first-chair-to-guide-eaus-policy-activities',
    'guidelines-collaboration-eau-cua-panel-develops-joint-mibc-guidelines',
    'guidelines-debate-in-favour-of-bcg-maintenance',
    'guidelines-office-at-the-eau-annual-congress',
    'guidelines-office-discusses-upper-urinary-tract-urothelial-carcinomas',
    'guidelines-office-update',
    'hands-on-training-applying-botulinumtoxin-a',
    'have-you-seen-the-new-website-of-european-urology',
    'higher-stone-risk-in-metabolic-syndrome-patients',
    'high-hopes-for-advances-in-bladder-cancer-treatment',
    'high-level-congress-of-the-association-of-urological-nurses',
    'highlighted-congress-abstract-sexual-activity-before-and-after-mid-urethral-sling',
    'high-risk-pca-urological-or-radiation-oncological-approach',
    'historical-posters-show-diverse-interests-of-researchers',
    'historical-symposium-polish-urology-and-its-contributions-to-european-urology',
    'history-congress-taking-urological-history-beyond-books',
    'history-office-explores-the-past-present-and-future-in-maastricht',
    'history-of-urology-meets-andrology',
    'hot-sessions-at-esou-2014-in-prague',
    'how-safe-is-the-use-of-surgical-mesh-in-urogynaecological-surgery',
    'how-to-develop-clinical-guidelines',
    'hybrid-coverage-virtual-esu-course-at-albanian-kosovan-congress',
    'image-guided-therapies-for-prostate-kidney-cancers-in-esuis-lisbon-meeting',
    'imaging-in-prostate-cancer-role-of-mri-to-expand-in-the-next-decade',
    'impact-factor-debut-for-eu-focus-and-all-time-high-for-european-urology',
    'impact-factor-for-european-urology-now-exceeds-10',
    'impact-of-prostate-cancer-on-european-society',
    'indolent-cancers-the-fine-art-of-sophisticated-pathology',
    'information-conveyed-to-urologists-regarding-laser-fiber-diameter-is-incorrect-says-new-study',
    'in-memoriam-chris-heyns-1949-2014',
    'in-memoriam-giorgio-pizzocaro-1939-2020',
    'in-memoriam-mr-richard-turner-warwick-1925-2020',
    'in-memoriam-prof-adolphe-steg-1926-2021',
    'in-memoriam-prof-dr-jaakko-elo-1925-2017',
    'in-memoriam-prof-dr-louis-denis-1933-2021',
    'in-memoriam-prof-jose-maria-gil-vernet-1922-2020',
    'in-memoriam-prof-mostafa-elhilali-1937-2017',
    'in-memoriam-rainer-engel-md-facs-1933-2018',
    'in-memoriam-rolf-ackermann-1941-2015',
    'innovation-in-minimally-invasive-techniques-for-the-treatment-of-bph',
    'innovations-in-non-muscle-invasive-bladder-cancer',
    'innovations-in-paediatric-urology-at-the-eau',
    'insights-on-active-surveillance-robotic-surgery-and-radiotherapy-for-pca',
    'inspiring-informative-seminars-for-urological-nurses',
    'inspiring-opening-of-a-forward-looking-eau14',
    'interactive-discussions-and-face-to-face-debates-during-esou',
    'international-centre-for-parliamentary-studies-to-host-pca-europe-2014-in-cooperation-with-eau',
    'international-links-to-open-25th-anniversary-eau-congress-tomorrow',
    'international-recognition-from-our-partners-worldwide',
    'interview-11th-meeting-of-the-eau-robotic-urology-section-in-amsterdam',
    'interview-eau-membership-office-chairman-prof-igor-korneyev',
    'interview-prof-karl-dietrich-sievert-on-the-2nd-joint-esffu-esgurs-esou-meeting',
    'interview-with-dr-john-heesakkers-2nd-joint-esffu-esgurs-esou-meeting',
    'interview-with-the-incoming-chairmen-of-the-eau-membership-office',
    'introducing-a-new-journal-european-urology-oncology',
    'introducing-the-eau20-virtual-congress-and-theme-week',
    'introducing-the-eau-resource-centre',
    'introducing-the-young-academic-urologists-working-party',
    'iraqi-urologic-community-to-bring-scientificity-to-the-forefront',
    'is-your-smartphone-congress-ready',
    'japan-eau-intensify-links-with-academic-exchanges-joint-meetings',
    'john-thomas-grayhack-1923-2012',
    'john-wickham-1929-2017',
    'join-our-free-webinar-on-pca-diagnosis',
    'joint-eau-sbu-scientific-program-brazilian-congress-of-urology-2015',
    'joint-esgurs-esau-meeting-challenges-and-prospects',
    'join-the-chinese-international-simulation-competition-for-young-urologists',
    'join-the-chinese-international-simulation-competition-for-young-urologists-2',
    'join-the-esffu-esou-esgurs-meeting-in-tubingen',
    'join-the-new-eau-suo-exchange-programme',
    'join-urology-week',
    'join-us-at-the-1st-esun-course-on-urinary-tract-infection',
    'junior-erus-session-to-open-erus-congress-in-stockholm',
    'key-urology-updates-advances-and-prospects-in-milan-congress',
    'kidney-cancer-every-minute-counts',
    'lack-of-clinical-use-hampers-potential-tumour-markers-in-pca',
    'late-breaking-news-a-preview',
    'late-breaking-news-positive-results-from-enzalutamide-study',
    'launch-of-forum-against-cancer-europe-in-the-european-parliament',
    'leadership-course-for-medical-professionals-at-eau18',
    'leipzig-to-welcome-present-and-future-of-surgical-technology',
    'lets-talk-prostate-cancer-campaign-launch',
    'lets-talk-prostate-cancer-digital-atlas-launched-shows-a-variance-in-pca-outcomes-between-european-countries',
    'lets-talk-prostate-cancer-expert-group-issues-five-calls-to-action-for-eu-policymakers-to-improve-pca-treatment',
    'letter-to-european-politicians-urges-them-to-set-clear-targets-on-prostate-cancer',
    'lifelong-premature-ejaculation-can-be-treated-by-pelvic-floor-exercises',
    'life-of-a-urologist-in-austria',
    'life-of-a-urologist-in-spain',
    'live-surgeries-show-new-techniques-in-partial-nephrectomy-and-stone-removal',
    'live-surgeries-to-highlight-new-techniques-at-eau-congress',
    'live-surgery-at-eau16-from-robots-to-smart-instruments',
    'live-surgery-at-erus16-nerve-sparing-radical-prostatectomy',
    'london-ready-for-1st-bi-annual-eulis-meeting',
    'macrophage-derived-mediators-may-have-potential-as-biomarkers-for-urinary-stone-risk',
    'magnolia-trial-now-in-54-sites-10-countries',
    'management-of-incontinence-surgery-complications',
    'many-thanks-to-those-leaving-the-guidelines-office',
    'mars-first-international-multicentre-observational-study-regarding-priapism',
    'masterclass-explores-new-ft-techniques-and-technologies',
    'members-are-invited-to-the-eau-general-assembly-in-munich',
    'memorable-25th-anniversary-eau-congress-in-barcelona-ends-with-awards',
    'message-from-the-regional-office-chairman-seem-goes-to-thessaloniki-greece',
    'microtechnologies-in-surgical-devices-microsystems-technology-expands-its-use-in-surgery-and-disease-detection',
    'mini-pcnl-versus-standard-pcnl-jury-is-still-out',
    'minsk-first-time-host-to-state-of-the-art-baltic-meeting',
    'moguc-the-esu-medical-oncology-course-in-marseille',
    'molecularly-targeted-precision-surgery-in-pca',
    'molecular-profiling-in-bladder-cancer',
    'more-than-350-participants-expected-at-cem-10th-anniversary-in-bratislava',
    'most-innovative-research-and-best-presentation-skills-awarded-at-9th-seem',
    'movember-urowebinar-debate-on-screening-for-pca',
    'multidisciplinary-approach-key-in-new-treatment-options-for-metastatic-castration-resistant-pca',
    'muscle-invasive-bladder-cancer',
    'my-first-year-as-a-resident-urology-doctor-at-the-university-of-rostock',
    'myths-and-realities-infectious-complications-after-endoscopic-surgery-of-urinary-stones',
    'national-societies-a-key-role-in-recruiting-future-urology-leaders',
    'nearly-1175-abstracts-in-poster-and-video-sessions',
    'new-dates-eau22-to-be-held-in-amsterdam-on-1-4-july',
    'new-developments-in-imaging-have-great-potential-to-change-treatment-in-the-coming-years',
    'new-diagnostic-techniques-for-pca-updates-in-bladder-and-kidney-cancers',
    'new-drug-therapies-for-luts-discussed-at-the-last-plenary-session',
    'new-eau-edu-platform-delivers-best-topic-based-content',
    'new-esou-chairman-talks-about-aims-current-onco-urology-challenges',
    'new-esu-ecourse-boosts-thromboprophylaxis-expertise',
    'new-esu-e-course-on-advanced-pca-debuts',
    'new-esu-e-courses-test-your-puc-and-nmibc-knowledge',
    'new-fellowship-applications',
    'new-from-european-urology-educational-resource-centres',
    'new-gpiu-study-dates-for-2022',
    'new-guidelines-based-e-course-focuses-on-mens-health',
    'new-history-office-publication-on-sale',
    'new-methodology-of-guideline-production-presented-by-urinary-incontinence-panel',
    'new-minimal-invasive-technology-needs-validation',
    'new-pn-masterclass-an-interview-with-prof-porpiglia',
    'new-policy-briefing-on-kidney-cancer',
    'new-report-urges-action-to-eliminate-87000-cancer-cases-caused-each-year-by-hpv-in-europe-in-women-and-men',
    'new-study-on-utis-suggests-flagellin-is-key-in-stimulating-bodys-natural-defences',
    'new-study-points-to-the-aggressive-potential-of-small-kidney-tumours-advocates-treatment',
    'new-thematic-session-introduces-urologists-to-neurourology',
    'new-trends-learning-tools-at-education-innovation',
    'new-yau-working-group-on-kidney-transplantation',
    'no-convincing-evidence-on-supplements-to-reduce-prostate-cancer-risks',
    'novel-coronavirus-and-the-upcoming-eau-congress',
    'novel-treatment-strategies-to-be-reviewed-at-challenge-the-experts-in-davos-switzerland',
    'number-of-submitted-abstracts-for-stockholm-exceeds-4000-mark',
    'nurses-enthusiastic-about-post-eaun-meetings',
    'nursing-management-of-prostate-cancer-in-italy',
    'nursing-solutions-for-difficult-cases',
    'oab-debate-botulinum-toxin-or-sacral-neuromodulation-for-chronic-luts',
    'object-of-the-month-teaching-the-art-of-cystoscopy-with-the-bladder-phantom',
    'object-of-the-month-the-bavarian-lithotomy-set',
    'official-launch-of-the-surgery-in-motion-school',
    'only-41-of-members-actively-collect-cme-credits',
    'optima-improving-prostate-cancer-treatment-through-ai',
    'paris-welcomes-the-27th-annual-eau-congress',
    'partnerships-in-oncopolicy',
    'patient-benefits-for-robot-led-procedures-should-be-closely-examined',
    'patient-information-launches-first-onco-urological-topic-kidney-cancer',
    'patient-selection-crucial-in-rcc-treatment',
    'pavone-macaluso-wins-eau-willy-gregoir-award',
    'pca17-interactive-compact-meeting-opens-in-vienna',
    'pca17-what-to-expect-in-future-prostate-cancer-management',
    'pca19-two-days-of-total-immersion-in-all-the-latest-treatment-options-for-prostate-cancer',
    'pctre-meeting-in-malmo-looks-at-pca-research-treatment-future',
    'pctre-meeting-two-way-communication-between-bench-and-clinic',
    'penile-and-testicular-cancer-late-effects-of-treatment',
    'penile-transplantation-programmes-in-europe-multidisciplinary-team-should-include-urological-surgeon',
    'percutaneous-management-of-kidney-stones-a-broad-perspective',
    'phe-osman-to-lead-yau-working-group-calls-for-new-members',
    'pioneer-virtual-studyathon-exploring-natural-history-of-prostate-cancer-in-patients-worldwide',
    'plenary-debates-a-critical-look-at-standard-therapies',
    'plenary-session-1-courting-controversy-providing-answers',
    'plenary-session-5-update-and-controversies-in-prostate-cancer',
    'plenary-session-on-urology-for-the-young-and-the-elderly',
    'plenary-sessions-core-issues-in-urology',
    'point-counterpoint-session-nmibc-is-a-second-tur-necessary',
    'poland-dominates-prize-winning-abstracts-at-4th-neem-in-riga',
    'poland-wins-big-in-best-abstracts-prizes-at-11th-cem',
    'polish-study-on-mri-ultrasound-for-targeted-prostate-biopsy-wins-cem-award',
    'poor-awareness-among-europeans-despite-rise-in-prostate-diseases',
    'post-congress-reflections-prof-manfred-wirth-looks-back-on-milan',
    'precision-mri-targeted-biopsy-strategy-leads-to-fewer-men-needing-biopsy',
    'prioritise-prospective-studies-and-real-world-data',
    'prize-winning-4th-neem-abstract-tissue-engineering-of-the-urinary-bladder-in-a-rat-model',
    'prof-albers-intention-is-to-enhance-the-scientific-quality',
    'professional-groups-a-key-role-in-boosting-prostate-cancer-research',
    'professor-schmid-leaves-prostate-cancer-panel-after-13-years',
    'prof-georg-bartsch-1942-2012',
    'profiles-new-chairs-for-robotics-urolithiasis-and-uropathology-sections',
    'prof-john-peter-blandy-cbe-1927-2011',
    'prof-kadioglu-highlights-expectations-of-eau-6th-south-eastern-european-meeting',
    'prof-liatsikos-gives-preview-of-surgery-focused-esut20',
    'prof-merseburger-takes-on-role-as-eusp-chair',
    'prof-p-abrams-more-exposure-to-incontinence-issues-is-necessary',
    'prof-peter-tenke-on-cem-15-sustaining-efforts-to-boost-regional-ties',
    'prof-thorsten-bach-appointed-eau-patient-information-consultant',
    'prof-walter-artibani-finding-the-balance-between-scientific-innovation-and-education-in-all-eau-activities',
    'promising-but-mixed-prospects-for-systemic-therapy-in-urological-cancers',
    'prospects-and-limits-of-chemotherapy-in-advanced-metastatic-pca',
    'prostate-cancer-aggressiveness-a-central-issue-at-the-22nd-esur-meeting-in-glasgow',
    'prostate-cancer-in-european-plan-to-beat-cancer',
    'prostate-cancer-prevention-group-tackles-limits-of-active-surveillance',
    'prostate-cancer-risk-calculator-offers-men-individual-risk-assessment',
    'prostate-cancer-studies-win-best-unmoderated-poster-awards-at-emuc15',
    'prostate-cancer-unlocking-new-treatment-strategies-through-next-generation-pathology',
    'prostate-stem-cell-study-examines-links-of-luminal-to-cancer-initiating-cells',
    'pure-science-9th-world-congress-on-urological-research',
    'radical-cystectomy-still-the-recommended-treatment',
    'reconsidering-metal-stenting-of-the-upper-urinary-tract-at-the-pre-conference-symposium-at-the-2nd-eulis-meeting',
    'reconstructive-surgery-study-won-top-prize-for-best-abstract-in-non-oncology',
    'reconstructive-urology-the-present-and-the-future',
    'record-abstract-submissions-for-eau17',
    'record-high-abstract-submissions-acceptance',
    'record-number-of-abstracts-for-emuc',
    'recurrence-of-prostate-cancer-is-significantly-lower-in-men-with-blood-group-o',
    're-evaluating-conventional-wisdom-on-testosterone-therapy',
    're-examining-tur-p-gold-standard-for-benign-prostate-obstruction',
    'refractory-problems-in-functional-urology-open-day-4-of-the-eau-congress',
    'register-now-for-the-24-hour-worldwide-live-robotic-surgery-event',
    'registration-for-eau21-is-now-open',
    'renewed-interest-in-immunotherapy-in-treating-kidney-cancer',
    'report-first-esut-fellowship-in-endourology-and-laparoscopy',
    'reporting-and-grading-of-complications-after-urological-surgical-procedures-an-ad-hoc-eau-guidelines-panel-assessment-and-recommendations',
    'report-live-reconstructive-surgery-and-expert-speakers-at-3rd-esgurs-section-meeting-in-istanbul',
    'report-live-surgery-spectacular-at-erus-meeting-in-stockholm',
    'report-the-future-of-metal-stenting-at-eulis-pre-meeting-symposium',
    'researchers-can-submit-their-work-for-prestigious-awards-bestowed-at-eau20',
    'residents-day-at-the-annual-eau-congress',
    'results-of-chemotherapy-new-organ-preservation-strategies-comparable-to-cystectomy',
    'results-of-eau-rf-precision-study-lead-to-changes-in-international-prostate-cancer-diagnosis-guidelines',
    'results-of-esuo-section-surveys-spark-discussion-of-what-should-be-considered-office-urology',
    'revamped-13th-eau-central-european-meeting-well-received-in-prague',
    'riga-study-on-infertile-men-wins-karl-storz-prize',
    'robotic-prostatectomy-a-viable-option-in-eastern-europe',
    'robotics-gain-ground-but-open-surgery-will-stay',
    'rodolfo-montironi-how-reliable-is-the-pathological-examination-to-make-clinical-decisions',
    'rogue-1-multicentric-prospective-t1-bca-registry',
    'romania-hosts-11th-cem-in-timisoara',
    'romanian-urologist-shares-his-experience-of-the-usanz-trainee-week',
    'romanian-urologist-shares-his-experience-of-the-usanz-trainee-week-2',
    'roobol-to-tackle-decision-making-challenges-in-urology-in-erasmus-trustfonds-inaugural-lecture',
    'safety-of-surgical-meshes-used-in-urogynaecological-surgery-final-opinion',
    'saturday-congress-highlight-what-do-we-tell-our-urolithiasis-patients',
    'scholarship-programme-challenges-and-future-prospects',
    'scholars-needed-to-avoid-inertia-in-urological-research',
    'scientific-programme-eau16-aims-to-trigger-medical-innovations',
    'section-meetings-from-in-depth-sessions-to-live-surgeries',
    'section-meetings-from-live-surgeries-to-debates',
    'seeding-grant-application-now-open',
    'seem15-day-1-training-the-essentials-of-laparoscopy',
    'seem15-day-2-courses-competitions-and-young-urologists',
    'seem16-mpmri-more-accurate-alternative-to-biopsy',
    'seem16-yuc-awardees-and-best-poster-presentation-winners',
    'serum-triglyceride-concentration-and-risk-of-pca-a-prospective-multicenter-study',
    'seven-reasons-why-you-should-be-on-twitter-for-eau14',
    'share-your-experience-contribute-to-the-european-urology-video-journal',
    'short-visit-to-rsch-a-great-milestone-in-my-career-as-a-urologist',
    'sign-up-for-the-european-association-of-urology-society-of-urologic-oncology-exchange-programme',
    'siu-honours-van-poppel-with-distinguished-career-award',
    'siu-offers-eau-members-free-access-to-siu-live-2021',
    'slovak-and-czech-studies-top-best-abstract-presentations-at-12th-cem',
    'social-media-at-the-28th-annual-eau-congress',
    'social-networking-for-urologists-there-is-more-than-meets-the-eye',
    'something-for-everybody-the-eulis-meeting-scientific-programme',
    'southern-turkey-to-host-11th-south-eastern-european-meeting',
    'star-trek-in-urology-surgery-jim-but-not-as-we-know-it',
    'state-of-the-art-lecture-beyond-the-androgen-receptor',
    'state-of-the-art-lecture-qa-dr-adrian-wagg-on-incontinence-and-age',
    'stenzl-takes-on-new-role-focuses-on-scientific-pursuits',
    'stockholm-to-host-10th-anniversary-erus-meeting',
    'stone-disease-and-patient-compliance',
    'stone-treatment-what-do-the-patients-want',
    'stubendorff-wins-rene-kuss-prize-for-original-work-on-renal-transplantation',
    'study-shows-pca-survival-rates-gap-between-western-and-eastern-europe',
    'submit-your-abstract-for-eau22-by-1-november-2021',
    'submit-your-abstracts-for-the-15th-russian-society-of-urology-congress',
    'successful-revival-of-regional-meeting-in-the-baltics',
    'summer-school-on-personalised-medicine-for-healthcare-professionals',
    'sunday-congress-highlight-esu-laparoscopy-for-beginners',
    'surgery-in-the-3rd-millennium-the-future-is-female',
    'surgery-or-radiotherapy-treatment-decision-for-high-risk-pca',
    'surgical-navigation-still-saddled-with-technical-limits',
    'survey-reveals-low-levels-of-awareness-in-men-about-prostate-health-and-function',
    'susanne-hieronymi-new-board-member',
    'swedish-researchers-win-best-abstract-in-oncology',
    'tackling-education-as-our-priority-target',
    'tailor-made-yuo-leadership-course-will-sharpen-key-competences-at-eau20',
    'take-a-free-esu-course-every-week',
    'targeted-and-immunotherapies-boosting-bladder-cancer-research',
    'technology-multidisciplinary-collaboration-drive-the-future-of-urology',
    'telling-the-story-of-urology-through-instruments',
    'test-your-eau-guidelines-knowledge-and-earn-cme-credits',
    'the-1st-esu-masterclass-on-lasers',
    'the-29th-annual-eau-congress-striking-a-balance',
    'the-7th-seem-unique-opportunities-narrow-focus',
    'the-congress-gift-joaquin-albarran',
    'the-davos-experience-an-interview-with-assoc-prof-christian-gratzke-and-prof-morgan-roupret',
    'the-eau-at-the-baus-2021-virtual-annual-meeting',
    'the-eau-rfs-start-up-package-for-beginning-researchers',
    'the-estro-update-prostate-cancer-today-and-tomorrow',
    'the-esu-on-day-1-of-erus15-keeping-education-in-step-with-technology',
    'the-esus-moguc-preparing-urologists-for-the-post-surgical-landscape',
    'the-esu-train-is-on-the-right-track',
    'the-european-school-of-urology-at-the-28th-annual-eau-congress',
    'the-highlights-of-esui15',
    'thematic-session-10-update-on-treatment-options-for-patients-with-crpc',
    'the-souvenir-session-breaking-news-and-highlights-from-the-scientific-programme',
    'too-much-antibiotic-use-by-urologists',
    'top-robotic-surgery-specialists-meet-in-london-september-26-28',
    'total-phalloplasty-in-female-transsexuals',
    'towards-a-urological-european-reference-network-share-care-and-cure',
    'treating-the-elderly-challenges-and-prospects',
    'treatment-of-bladder-pain-syndrome',
    'tricks-of-the-trade-robotic-assisted-laparoscopic-prostatectomy',
    'tuesday-congress-highlight-ethics-of-live-surgery',
    'tumour-characteristics-and-patient-profile-crucial-issues-in-pca-treatment',
    'turkey-scores-big-in-6th-seems-abstract-race',
    'twitter-the-eulis-meeting-and-the-eau',
    'two-recipients-of-the-eau-rf-seeding-grant-set-to-start-new-projects',
    'understanding-the-role-of-biomarkers-in-pca-treatment',
    'update-interventional-imaging-in-upper-urinary-tract-stone-disease',
    'update-on-eau-rf-magnolia-trial-recruitment-figures',
    'update-on-eons-prostate-cancer-education-project',
    'update-on-european-reference-networks-erns',
    'update-on-reconstructive-urology-for-serbian-urologists',
    'update-on-the-diagnosis-and-treatment-of-bladder-cancer',
    'updates-in-prostate-cancer-multidisciplinary-approach-and-patient-involvement',
    'upper-urinary-tract-challenges-and-prospects',
    'urgent-need-for-prostate-cancer-screening-amongst-dutch-men',
    'urine-biomarkers-may-detect-disease-state-in-nmibc',
    'urological-groups-link-up-for-african-urology',
    'urological-surgeons-receive-inaugural-christchurch-medal-for-bravery',
    'urologists-medical-and-radiation-oncologists-come-together-at-5th-emuc',
    'urology-beyond-europe',
    'urology-beyond-europe-eau-touches-base-with-overseas-colleagues',
    'urology-beyond-europe-making-a-difference-in-urology',
    'urology-beyond-europe-opinion-leaders-stress-closer-collaboration',
    'urology-in-maghreb-region-developing-at-a-fast-rate',
    'urologys-future-begins-at-school',
    'urologys-future-rests-on-innovative-advances-in-key-areas',
    'urology-week-2011-translating-diversity-into-impact',
    'urology-week-2012-launched-on-monday',
    'urology-week-2012-stone-disease-and-patient-information',
    'urology-week-2013-benign-prostatic-enlargement',
    'urology-week-2015-eu-meeting-press-campaigns-to-patient-counselling',
    'urology-week-2021-shining-the-spotlight-on-incontinence',
    'uro-oncology-management-issues-debates-top-esou-agenda-in-london',
    'urothelial-cancer-evidence-and-new-outcomes-awaited-for-better-decision-making',
    'using-mri-before-biopsy-a-divisive-topic',
    'value-of-psa-screening-assessed-at-world-chinese-joint-session',
    'van-poppel-receives-francisco-diaz-medal',
    'virtual-esu-course-delivers-bph-fundamentals-in-baghdad',
    'walter-artibani-urologist-by-chance',
    'walter-artibani-wins-john-wickham-award-at-erus19',
    'we-cannot-talk-to-patients-unless-we-speak-their-language',
    'what-is-multidisciplinary-for-the-eau-estro-and-esmo',
    'what-it-takes-to-be-a-eurep-tutor-search-is-on-for-new-mentors',
    'whats-new-at-the-annual-eau-congress',
    'when-we-facilitate-collaboration-between-different-countries-we-will-gain-much-more-knowledge',
    'where-do-we-stand-with-predictive-biomarkers-in-mcrpc',
    'why-early-detection-for-prostate-cancer-is-vital',
    'wrap-up-read-our-coverage-of-eau21-as-it-happened',
    'yau-names-dr-gomez-rivas-as-new-chairman',
    'yau-survey-attitudes-to-high-risk-prostate-cancer',
    'young-urologists-competition-presents-fresh-insights-at-baltic17',
    'young-urologists-paint-a-picture-of-the-future-of-robotics',
    'your-guide-to-esu-courses-at-eau21',
    'yuo-leadership-course-during-eau20',
    'zeus-study-yields-no-survival-benefits-and-positive-results',
];

const staticRedirects = [
    { from: '/section/esgurs', to: '/sections/eau-section-of-genitourinary-reconstructive-surgeons' },
    { from: '/about-eau', to: '/about' },
    { from: '/about-eau/eau-history', to: '/about/eau-history' },
    { from: '/about-eau/engage-with-eau', to: '/contact' },
    { from: '/about-eau/history-of-urology', to: '/about/eau-history' },
    { from: '/about-eau/history-of-urology/board-members', to: '/offices/eau-history-office' },
    { from: '/about-eau/history-of-urology/projects', to: '/about/eau-history' },
    { from: '/about-eau/history-of-urology/publications', to: '/science-publications/eau-book-collection' },
    { from: '/about-eau/how-we-work', to: '/about/how-we-work' },
    { from: '/about-eau/how-we-work/annual-financial-assessment-european-medicines-agency/' },
    { from: '/about-eau/how-we-work/eau-code-of-ethics', to: '/about/how-we-work/eau-code-of-ethics' },
    { from: '/about-eau/how-we-work/organizational-structure', to: '/about/how-we-work' },
    { from: '/about-eau/how-we-work/vacancies', to: '/about/vacancies' },
    { from: '/about-eau/our-partners', to: '/about/how-we-work/our-partners' },
    { from: '/about-eau/our-partners/eau-support-fund', to: '/international-relations/eau-support-fund' },
    { from: '/about-eau/our-partners/global-philanthropic-committee', to: '/international-relations/global-philanthropic-committee' },
    { from: '/about-eau/who-we-are', to: '/about' },
    { from: '/about-eau/who-we-are/eau-clinical-practice', to: '/about/how-we-work' },
    { from: '/about-eau/who-we-are/eau-communication', to: '/about/how-we-work' },
    { from: '/about-eau/who-we-are/eau-education', to: '/offices/eau-education-office-esu' },
    { from: '/about-eau/who-we-are/eau-governance', to: '/about/how-we-work' },
    { from: '/about-eau/who-we-are/eau-science', to: '/about/how-we-work' },
    { from: '/all-publications', to: '/science-publications' },
    { from: '/annual-eau-congresses', to: '/education-events/events?topic=&type=annual-eau-congress&location=&page=1' },
    { from: '/apply-form-estu-fellowship', to: '/education/scholarships/estu-fellowship-on-kidney-transplant' },
    { from: '/apply-form-eulis-programme', to: '/education/scholarships/eulis-fellowship-in-endourology' },
    { from: '/apply-form-reprounion', to: '/education/scholarships/fellowship' },
    { from: '/awards/crystal-matula', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/eau-ernest-desnos-prize', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/eau-frans-debruyne-life-time-achievement-award', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/eau-hans-marberger-award', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/eau-innovators-in-urology-award', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/eau-prostate-cancer-research-award', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/eau-willy-gregoir-medal', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/honorary-members', to: 'http://eaucongresshistory.org/' },
    { from: '/awards/past-presidents', to: 'http://eaucongresshistory.org/' },
    { from: '/become-a-member-helium', to: '/about/why-join-the-eau' },
    { from: '/blog/12th-esou-meeting-discusses-personalised-medicine-and-the-future-of-onco-urology', to: '/sections/eau-section-of-oncological-urology' },
    { from: '/blog/2nd-eulis-ureteroscopy-workshop', to: '/sections/eau-section-of-urolithiasis' },
    { from: '/blog/4th-meeting-endourology-and-minimally-invasive-surgery-sofia-bulgaria-25-26-april-2013', to: '/sections/eau-section-of-urolithiasis' },
    { from: '/blog/5-years-of-steps-the-way-forward', to: '/sections/eau-section-of-oncological-urology' },
    { from: '/blog/all-about-nocturia-during-the-cyprus-meeting', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/all-about-the-psa-test-in-the-pan-hellenic-congress', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/andrology-and-office-urology-in-close-co-operation-not-only-during-the-eluts2019', to: '/topics/prostate-cancer' },
    { from: '/blog/biomarkers-in-overactive-bladder-where-do-we-stand-today', to: '/sections/eau-section-of-female-functional-urology' },
    { from: '/blog/cordial-contacts-to-slovakia', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/erus13-fistula-by-hubert-john', to: '/sections/eau-robotic-urology-section' },
    { from: '/blog/esuo-holds-inaugural-meeting-at-eau17-in-london', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/esuo-online-education-is-starting', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/esuo-three-tasks-during-the-eau2019-in-barcelona', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/esut-in-european-urology-today-attitude-tracking-technology', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-challenges-in-laparoscopy-and-robotics-2013', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-challenges-in-laparoscopy-robotics-2014', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-enthusiastic-response-for-e-blus-course-in-indonesia', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-esut-experts-join-moroccan-course-on-nmibc', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-improvements-in-ipad-assisted-renal-surgery', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-live-surgery-transmission-at-the-29th-annual-eau-congress-in-stockholm', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-mini-laparoscopy-in-urology', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-european-urology-today-modular-training-in-percutaneous-nephrolithotripsy', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-uropean-urology-today-esuts-dynamic-presence-in-milan', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/esut-in-uropean-urology-today-magnetic-anchoring-and-guidance-systems-mags', to: '/sections/eau-section-of-uro-technology' },
    { from: '/blog/eulis17-4th-meeting-of-the-eau-section-of-urolithiasis', to: '/sections/eau-section-of-urolithiasis' },
    { from: '/blog/eulis-session-stresses-diversity-of-stone-disease', to: '/sections/eau-section-of-urolithiasis' },
    { from: '/blog/influence-of-novel-imaging-techniques-on-prostate-cancer-treatment', to: '/topics/prostate-cancer' },
    { from: '/blog/innovations-and-classics-in-endourology-mannheim-germany', to: '/topics/urolithiasis' },
    { from: '/blog/interview-with-esur-chair-on-uroweb', to: '/sections/eau-section-of-urological-research' },
    { from: '/blog/issues-and-challenges-in-small-renal-masses', to: '/sections/eau-section-of-urological-imaging' },
    { from: '/blog/putting-office-urology-up-front-at-eau18-in-copenhagen', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/siut-hosts-a-joint-meeting-together-with-the-esuo', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/the-esuo-section-launches-an-own-scientific-project', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/the-new-esuo-column-in-eut', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/blog/updates-and-prospects-in-bladder-cancer', to: '/topics/bladder-cancer' },
    { from: '/course/advanced-prostate-cancer-basis-of-androgen-deprivation-therapy', to: '/education-events/advanced-prostate-cancer-basis-of-androgen-deprivation-therapy' },
    { from: '/course/advanced-prostate-cancer-metastatic-hormone-sensitive-prostate-cancer-mhspc-new', to: '/education-events/advanced-prostate-cancer-metastatic-hormone-sensitive-prostate-cancer-mhspc' },
    { from: '/course/augmented-reality-robotic-surgery', to: '/education-events/augmented-reality-robotic-surgery' },
    { from: '/course/bladder-neoplasms-will-a-morphological-classification-become-obsolete', to: '/education-events/bladder-neoplasms-will-a-morphological-classification-become-obsolete' },
    { from: '/course/bulbar-urethral-stricture-management', to: '/education-events/bulbar-urethral-stricture-management' },
    { from: '/course/challenges-in-differential-diagnosis-of-male-luts-bpe', to: '/education-events/challenges-in-different-diagnosis-of-male-luts-bpe' },
    { from: '/course/covid-19-and-urological-management-of-stone-disease', to: '/education-events/covid-19-and-urological-management-of-stone-disease' },
    { from: '/course/eau-guidelines-on-bladder-stones', to: '/education-events/eau-guidelines-on-bladder-stones' },
    { from: '/course/eau-guidelines-on-chronic-pelvic-pain', to: '/education-events/eau-guidelines-on-chronic-pelvic-pain' },
    { from: '/course/eau-guidelines-on-mens-health', to: '/education-events/eau-guidelines-on-mens-health' },
    { from: '/course/eau-guidelines-on-muscle-invasive-and-metastatic-bladder-cancer', to: '/education-events/eau-guidelines-on-muscle-invasive-and-metastatic-bladder-cancer' },
    { from: '/course/eau-guidelines-on-non-muscle-invasive-bladder-cancer', to: '/education-events/eau-guidelines-on-non-muscle-invasive-bladder-cancer' },
    { from: '/course/eau-guidelines-on-paediatric-urology', to: '/education-events/education?topic=&type=e-course&page=1' },
    { from: '/course/eau-guidelines-on-primary-urethral-carcinoma', to: '/education-events/eau-guidelines-on-primary-urethral-carcinoma' },
    { from: '/course/eau-guidelines-on-prostate-cancer', to: '/education-events/eau-guidelines-on-prostate-cancer' },
    { from: '/course/eau-guidelines-on-renal-cell-cancer', to: '/education-events/eau-guidelines-on-renal-cell-carcinoma' },
    { from: '/course/eau-guidelines-on-renal-transplantation', to: '/education-events/eau-guidelines-on-renal-transplantation' },
    { from: '/course/eau-guidelines-on-thromboprophylaxis', to: '/education-events/eau-guidelines-on-thromboprophylaxis' },
    { from: '/course/eau-guidelines-on-urinary-incontinence', to: '/education-events/eau-guidelines-on-urinary-incontinence' },
    { from: '/course/eau-guidelines-on-urolithiasis', to: '/education-events/eau-guidelines-on-urolithiasis' },
    { from: '/course/eau-guidelines-on-urological-infections', to: '/education-events/education?topic=&type=e-course&page=1' },
    { from: '/course/e-blus', to: '/education-events/education?topic=&type=e-course&page=1' },
    { from: '/course/e-brus-theoretical-course', to: '/education-events/e-brus-theoretical-course' },
    { from: '/course/endourology-new-technology-and-surgeon-ergonomics-basis-programme-2021', to: '/education-events/endourology-new-technology-and-surgeon-ergonomics-basis-programme' },
    { from: '/course/genetics-in-stone-disease', to: '/education-events/genetics-in-stone-disease' },
    { from: '/course/glsnu', to: '/education-events/eau-guidelines-on-neuro-urology' },
    { from: '/course/glstestiscancer', to: '/education-events/education?topic=&type=e-course&page=1' },
    { from: '/course/glsutuc', to: '/education-events/eau-guidelines-on-upper-urinary-tract-urothelial-cell-carcinoma' },
    { from: '/course/how-to-manage-cvd-risk-in-pca-patients-during-covid-19', to: '/education-events/how-to-manage-cvd-risk-in-pca-patients-during-covid-19' },
    { from: '/course/introduction-to-upper-urinary-tract-endoscopy-for-stones', to: '/education-events/introduction-to-upper-urinary-tract-endoscopy-for-stones' },
    { from: '/course/laser-endoscopic-anatomic-enucleation-of-prostate-aeep', to: '/education-events/basis-urology-all-about-the-laser-endoscopic-anatomic-enucleation-of-prostate-aeep' },
    { from: '/course/management-of-paediatric-urolithiasis', to: '/education-events/management-of-paediatric-urolithiasis' },
    { from: '/course/mcrpc', to: '/education-events/advanced-prostate-cancer-metastatic-castration-resistant-prostate-cancer-mcrpc' },
    { from: '/course/medical-management-of-urolithiasis', to: '/education-events/medical-management-of-urolithiasis' },
    { from: '/course/metastatic-hormone-sensitive-prostate-cancer', to: '/education-events/metastatic-hormone-sensitive-prostate-cancer' },
    { from: '/course/metastatic-prostate-cancer-online-course', to: '/education-events/advanced-prostate-cancer-basis-of-androgen-deprivation-therapy' },
    { from: '/course/miniaturization-in-endoscopic-procedures', to: '/education-events/miniaturization-in-endoscopic-procedures' },
    { from: '/course/miniaturization-in-endoscopic-procedures-2', to: '/education-events/miniaturization-in-endoscopic-procedures' },
    { from: '/course/module-1-cvd-in-prostate-cancer-patients-increased-risks-comorbidities', to: '/education-events/cvd-in-prostate-cancer-patients-increased-risks-comorbidities' },
    { from: '/course/module-3-advances-in-safety-in-the-treatment-of-pca-01-09-2021', to: '/education-events/advances-in-safety-in-the-treatment-of-pca' },
    { from: '/course/module-3-advances-in-safety-in-the-treatment-of-pca-2021', to: '/education-events/advances-in-safety-in-the-treatment-of-pca' },
    { from: '/course/new-technologies-in-urology-academics-and-research', to: '/education-events/new-technologies-in-urology-academics-and-research' },
    { from: '/course/nmcrpc', to: '/education-events/advanced-prostate-cancer-non-metastatic-castration-resistant-prostate-cancer-nmcrpc' },
    { from: '/course/nmibc', to: '/education-events/non-muscle-invasive-bladder-cancer' },
    { from: '/course/non-neurogenic-female-luts-2', to: '/education-events/non-neurogenic-female-luts' },
    { from: '/course/outstanding-challenges-in-medical-treatment-of-male-luts-bpe', to: '/education-events/outstanding-challenges-in-medical-treatment-of-male-luts-bpe' },
    { from: '/course/overactive-bladder-mechanisms-and-management', to: '/education-events/overactive-bladder-mechanisms-and-management' },
    { from: '/course/overactive-bladder-onabotulinumtoxina-as-treatment', to: '/education-events/overactive-bladder-onabotulinumtoxina-as-treatment' },
    { from: '/course/pcnl-in-children-tips-and-tricks', to: '/education-events/pcnl-in-children-tips-and-tricks' },
    { from: '/course/practical-approach-to-paediatric-urology-2021', to: '/education-events/practical-approach-to-paediatric-urology' },
    { from: '/course/prostate-biopsy-and-infectious-complications', to: '/education-events/prostate-biopsy-and-infectious-complications' },
    { from: '/course/relevance-of-68ga-psma11-in-prostate-cancer-patient-management-2', to: '/education-events/relevance-of-68ga-psma11-in-prostate-cancer-patient-management' },
    { from: '/course/risk-profile-oriented-management-of-bpeluts', to: '/education-events/risk-profile-oriented-management-of-bpe-luts' },
    { from: '/course/snm-oab-that-is-patient-centric-focusing-on-patient-choice-and-patient-follow', to: '/education-events/snm-oab-that-is-patient-centric-focusing-on-patient-choice-and-patient-follow' },
    { from: '/course/stress-urinary-incontinence-treatment-options', to: '/education-events/stress-urinary-incontinence-treatment-options' },
    { from: '/course/surgical-treatment-of-post-prostatectomy-incontinence-artificial-urinary-sphincter-or-male-sling', to: '/education-events/surgical-treatment-of-post-prostatectomy-incontinence-artificial-urinary-sphincter-or-male-sling' },
    { from: '/course/understanding-the-tumor-micro-environment-in-urological-cancers-to-improve-immunotherapy', to: '/education-events/understanding-the-tumor-micro-environment-in-urological-cancers-to-improve-immuno-therapy' },
    { from: '/course/understanding-the-tumor-micro-environment-in-urological-cancers-to-improve-immunotherapy-2', to: '/education-events/understanding-the-tumor-micro-environment-in-urological-cancers-to-improve-immuno-therapy' },
    { from: '/course/update-on-robot-assisted-partial-nephrectomy', to: '/education-events/update-on-robot-assisted-partial-nephrectomy' },
    { from: '/course/update-on-robotic-reconstructive-surgery', to: '/education-events/update-on-robotic-reconstructive-surgery' },
    { from: '/course/urogenital-tuberculosis', to: '/education-events/urogenital-tuberculosis' },
    { from: '/course/uromotion-penile-prosthesis-in-men-with-peyronies-disease', to: '/education-events/penile-prosthesis-in-men-with-peyronies-disease' },
    { from: '/course/urowebinar-4th-basis-urology-en-bloc-resection-of-bladder-tumour-erbt-and-applied-technologies/' },
    { from: '/course/urowebinar-an-update-on-fluorescence-guided-surgery', to: '/education-events/an-update-on-fluorescence-guided-surgery' },
    { from: '/course/urowebinar-bcg-unresponsive-disease-how-this-definition-impact-your-treatment-decision-making', to: '/education-events/bcg-unresponsive-disease-how-this-definition-impact-your-treatment-decision-making' },
    { from: '/course/urowebinar-bladder-cancer-turb', to: '/education-events/bladder-cancer-turb' },
    { from: '/course/urowebinar-complex-penile-implant-surgery', to: '/education-events/complex-penile-implant-surgery' },
    { from: '/course/urowebinar-covid-19-and-urology-what-we-have-learned-from-the-pandemic', to: '/education-events/covid-19-and-urology-what-we-have-learned-from-the-pandemic' },
    { from: '/course/urowebinar-covid-19-recommendations-for-onco-urologists', to: '/education-events/covid-19-recommendations-for-onco-urologists' },
    { from: '/course/urowebinar-covid-19-recommendations-for-onco-urologists-2', to: '/education-events/covid-19-recommendations-for-onco-urologists' },
    { from: '/course/urowebinar-current-laparoscopic-urology-and-allied-technologies-13-01-2022', to: '/education-events/basis-urology-programme-current-laparoscopic-urology-and-allied-technologies' },
    { from: '/course/urowebinar-evolving-interventional-therapy-for-bladder-outlet-obstruction-in-men-where-do-we-stand', to: '/education-events/evolving-interventional-therapy-for-bladder-outlet-obstruction-in-men-where-do-we-stand' },
    { from: '/course/urowebinar-female-urethral-stricture-management', to: '/education-events/female-urethral-stricture-management' },
    { from: '/course/urowebinar-future-roles-for-liquid-biopsies-in-clinical-decision-making-in-uro-oncology', to: '/education-events/future-roles-for-liquid-biopsies-in-clinical-decision-making-in-uro-oncology' },
    { from: '/course/urowebinar-identification-and-management-of-patients-with-nmcrpc', to: '/education-events/identification-and-management-of-patients-with-nmcrpc' },
    { from: '/course/urowebinar-imaging-and-psa-assessment-in-nmcrpc-patients-and-the-treatment-consequences', to: '/education-events/imaging-and-psa-assessment-in-nmcrpc-patients-and-the-treatment-consequences' },
    { from: '/course/urowebinar-laparoscopy-and-robotics-how-present-is-turning-into-future', to: '/education-events/laparoscopy-and-robotics-how-present-is-turning-into-future' },
    { from: '/course/urowebinar-limits-of-flexible-ureteroscopy', to: '/education-events/limits-of-flexible-ureteroscopy' },
    { from: '/course/urowebinar-local-intravesical-instillation-therapy-of-ic-bps-present-and-future', to: '/education-events/local-intravesical-instillation-therapy-of-ic-bps-present-and-future-2' },
    { from: '/course/urowebinar-minimally-invasive-treatment-for-male-luts-novel-armamentarium', to: '/education-events/minimally-invasive-treatment-for-male-luts-novel-armamentarium' },
    { from: '/course/urowebinar-penile-cancer-reconstruction', to: '/education-events/penile-cancer-reconstruction' },
    { from: '/course/urowebinar-practical-neuro-urology-14-09-2021', to: '/education-events/education' },
    { from: '/course/urowebinar-present-and-future-of-swl', to: '/education-events/present-and-future-of-swl' },
    { from: '/course/urowebinar-radical-cystectomy-the-urologist-nightmare', to: '/education-events/radical-cystectomy-the-urologist-nightmare' },
    { from: '/course/urowebinar-robotic-cystectomy-and-diversion-an-update', to: '/education-events/robotic-cystectomy-and-diversion-an-update' },
    { from: '/course/urowebinar-robotic-partial-nephrectomy-the-evolution-of-a-technique', to: '/education-events/robotic-partial-nephrectomy-the-evolution-of-a-technique' },
    { from: '/course/urowebinar-the-impact-of-bph-on-sexual-function', to: '/education-events/the-impact-of-bph-on-sexual-function' },
    { from: '/course/urowebinar-the-new-eau-male-sexual-and-reproductive-health-guidelines-male-hypogonadism-and-male-infertility', to: '/education-events/the-new-eau-male-sexual-and-reproductive-health-guidelines-male-hypogonadism-and-male-infertility' },
    { from: '/course/urowebinar-the-use-of-proms-in-the-evaluation-of-rarp', to: '/education-events/the-use-of-proms-in-the-evaluation-of-rarp' },
    { from: '/course/urowebinar-update-from-the-eau-urological-trauma-guidelines-management-of-upper-tract-trauma', to: '/education-events/update-from-the-eau-urological-trauma-guidelines-management-of-upper-tract-trauma' },
    { from: '/course/urowebinar-update-in-robotics-and-normothermic-perfusion-in-renal-preservation-2', to: '/education-events/update-in-robotics-and-normothermic-perfusion-in-renal-preservation' },
    { from: '/course/whats-new-in-male-infertility', to: '/education-events/whats-new-in-male-infertility' },
    { from: '/course/whats-next-a-patient-with-diabetes-obesity-and-lower-urinary-tract-symptoms-luts', to: '/education-events/education?topic=&type=e-course&page=1' },
    { from: '/eau16twitter', to: '/' },
    { from: '/eau-events-app-your-smart-congress-companion', to: '/' },
    { from: '/eau-press-centre', to: '/science-publications/press-releases' },
    { from: '/eau-press-releases', to: '/science-publications/press-releases' },
    { from: '/eau-privacy-policy', to: '/privacy-policy' },
    { from: '/education/about-esu', to: '/education' },
    { from: '/education/about-esu/about-the-esu', to: '/about/how-we-work/the-european-school-of-urology-esu' },
    { from: '/education/about-esu/apply-for-live-surgery', to: '/about/how-we-work/the-european-school-of-urology-esu' },
    { from: '/education/about-esu/esu-board', to: '/offices/eau-education-office-esu' },
    { from: '/education/about-esu/esu-committees', to: '/about/how-we-work/the-european-school-of-urology-esu' },
    { from: '/education/about-esu/esu-teaching-activities', to: '/education-events/education' },
    { from: '/education/accreditation/collecting-cmecpd-credits', to: '/education/accreditation' },
    { from: '/education/accreditation/continuing-professional-development', to: '/education/accreditation' },
    { from: '/education/accreditation/eu-acme-membership', to: '/education/accreditation' },
    { from: '/education/exchange-programmes/exchange-australia', to: '/education/exchange-programmes/eau-usanz-exchange-programme' },
    { from: '/education/exchange-programmes/exchange-canada', to: '/education/exchange-programmes/eau-cua-exchange-programme' },
    { from: '/education/exchange-programmes/exchange-japan', to: '/education/exchange-programmes/eau-jua-exchange-programme' },
    { from: '/education/exchange-programmes/exchange-taiwan', to: '/education/exchange-programmes/eau-tua-exchange-programme' },
    { from: '/education/exchange-programmes/exchange-usa', to: '/education/exchange-programmes/eau-aua-exchange-programme' },
    { from: '/education/exchange-programmes/society-of-urologic-oncology-exchange-programme', to: '/education/exchange-programmes/eau-suo-exchange-programme' },
    { from: '/education/learning-resources', to: '/education' },
    { from: '/education/learning-resources/eau-edu-platform', to: '/education/educational-platforms' },
    { from: '/education/learning-resources/eu-focus', to: '/science-publications/european-urology' },
    { from: '/education/learning-resources/european-urology', to: '/science-publications/european-urology' },
    { from: '/education/learning-resources/european-urology-oncology', to: '/science-publications/european-urology' },
    { from: '/education/learning-resources/european-urology-open-science', to: '/science-publications/european-urology' },
    { from: '/education/learning-resources/guidelines', to: '/guidelines' },
    { from: '/education/learning-resources/patient-information', to: 'https://patients.uroweb.org/' },
    { from: '/education/learning-resources/urosource-2', to: 'https://urosource.uroweb.org/' },
    { from: '/education/live-events', to: '/education-events/events' },
    { from: '/education/live-events/masterclasses-3', to: '/education-events/events?topic=&type=masterclass&location=&page=1' },
    { from: '/education/live-events/meetings', to: '/education-events/events' },
    { from: '/education/online-education', to: '/education-events/education' },
    { from: '/education/online-education/eau-edu-platform', to: '/education/educational-platforms' },
    { from: '/education/online-education/eau-podcasts', to: '/education-events/education' },
    { from: '/education/online-education/e-courses', to: '/education-events/education' },
    { from: '/education/online-education/surgical-education', to: '/education-events/education' },
    { from: '/education/online-education/webinar-e-courses', to: '/education-events/education' },
    { from: '/education/online-education/webinars', to: '/education-events/education' },
    { from: '/education/scholarship', to: '/education/scholarships' },
    { from: '/education/scholarship/about-the-eusp', to: '/education/scholarships' },
    { from: '/education/scholarship/certified-host-centres', to: '/education/scholarships' },
    { from: '/education/scholarship/how-to-apply', to: '/education/scholarships' },
    { from: '/education/scholarship/programmes', to: '/education/scholarships' },
    { from: '/education/scholarship/reports', to: '/education/scholarships' },
    { from: '/education/young-urologists-office-yuo', to: '/offices/young-urologists-office' },
    { from: '/education/young-urologists-office-yuo/esru', to: '/offices/european-society-of-residents-in-urology-esru' },
    { from: '/education/young-urologists-office-yuo/yau', to: '/offices/young-academic-urologists' },
    { from: '/education/young-urologists-office-yuo/yuo', to: '/offices/young-urologists-office' },
    { from: '/embargo-policy', to: '/science-publications/press-releases' },
    { from: '/events', to: '/education-events/events' },
    { from: '/events/attended-meetings', to: '/education-events/events' },
    { from: '/events/calendar', to: '/education-events/events' },
    { from: '/events/certificates-of-attendance', to: '/login' },
    { from: '/events/eau-congress-history', to: 'http://eaucongresshistory.org/' },
    { from: '/faq', to: 'https://urosource.uroweb.org/' },
    { from: '/group-reservation-form', to: 'https://eaucongress.uroweb.org/group-reservation-form/' },
    { from: '/group-reservation-form-eaun-congress', to: 'https://eauncongress.uroweb.org/group-reservation-form/' },
    { from: '/guideline/bladder-cancer-muscle-invasive-and-metastatic', to: '/guidelines/muscle-invasive-and-metastatic-bladder-cancer' },
    { from: '/guideline/bladder-stones', to: '/guidelines/urolithiasis' },
    { from: '/guideline/chronic-pelvic-pain', to: '/guidelines/chronic-pelvic-pain' },
    { from: '/guideline/covid-19-recommendations', to: '/guidelines' },
    { from: '/guideline/general-policy-methodology-dissemination', to: '/eau-guidelines/methodology-policies' },
    { from: '/guideline/laparoscopy', to: '/eau-guidelines/discontinued-topics/laparoscopy' },
    { from: '/guideline/lasers-and-technology', to: '/eau-guidelines/discontinued-topics/lasers-and-technology' },
    { from: '/guideline/male-hypogonadism', to: '/eau-guidelines/discontinued-topics/male-hypogonadism' },
    { from: '/guideline/male-infertility', to: '/eau-guidelines/discontinued-topics/male-infertility' },
    { from: '/guideline/male-sexual-dysfunction', to: '/eau-guidelines/discontinued-topics/male-sexual-dysfunction' },
    { from: '/guideline/neuro-urology', to: '/guidelines/neuro-urology' },
    { from: '/guideline/non-muscle-invasive-bladder-cancer', to: '/guidelines/non-muscle-invasive-bladder-cancer' },
    { from: '/guideline/non-neurogenic-female-luts', to: '/guidelines/non-neurogenic-female-luts' },
    { from: '/guideline/paediatric-urology', to: '/guidelines/paediatric-urology' },
    { from: '/guideline/pain-management', to: '/eau-guidelines/discontinued-topics/pain-management' },
    { from: '/guideline/penile-cancer', to: '/guidelines/penile-cancer' },
    { from: '/guideline/penile-curvature', to: '/eau-guidelines/discontinued-topics/penile-curvature' },
    { from: '/guideline/priapism', to: '/eau-guidelines/discontinued-topics/priapism' },
    { from: '/guideline/primary-urethral-carcinoma', to: '/guidelines/primary-urethral-carcinoma' },
    { from: '/guideline/prostate-cancer', to: '/guidelines/prostate-cancer' },
    { from: '/guideline/renal-cell-carcinoma', to: '/guidelines/renal-cell-carcinoma' },
    { from: '/guideline/renal-transplantation', to: '/guidelines/renal-transplantation' },
    { from: '/guideline/reporting-complications', to: '/guidelines' },
    { from: '/guideline/robotic-and-single-site-surgery-in-urology', to: '/eau-guidelines/discontinued-topics/robotic-and-single-site-surgery-in-urology' },
    { from: '/guideline/sexual-and-reproductive-health', to: '/guidelines/sexual-and-reproductive-health' },
    { from: '/guideline/standardised-medical-terminology', to: '/guidelines' },
    { from: '/guideline/testicular-cancer', to: '/guidelines/testicular-cancer' },
    { from: '/guideline/thromboprophylaxis', to: '/guidelines/thromboprophylaxis' },
    { from: '/guideline/treatment-of-non-neurogenic-male-luts', to: '/guidelines/management-of-non-neurogenic-male-luts' },
    { from: '/guideline/upper-urinary-tract-urothelial-cell-carcinoma', to: '/guidelines/upper-urinary-tract-urothelial-cell-carcinoma' },
    { from: '/guideline/urethral-strictures', to: '/guidelines/urethral-strictures' },
    { from: '/guideline/urinary-incontinence', to: '/eau-guidelines/discontinued-topics/urinary-incontinence' },
    { from: '/guideline/urolithiasis', to: '/guidelines/urolithiasis' },
    { from: '/guideline/urological-infections', to: '/guidelines/urological-infections' },
    { from: '/guideline/urological-trauma', to: '/guidelines/urological-trauma' },
    { from: '/guidelines/about', to: '/eau-guidelines' },
    { from: '/guidelines/about/2021-eau-guidelines-reviewers', to: '/eau-guidelines' },
    { from: '/guidelines/about/eau-guidelines-office-committees', to: '/eau-guidelines' },
    { from: '/guidelines/compilations-of-all-guidelines', to: '/eau-guidelines' },
    { from: '/guidelines/contact', to: '/eau-guidelines' },
    { from: '/guidelines/disclaimer', to: '/eau-guidelines/disclaimer' },
    { from: '/guidelines/eau-pocket-guidelines-app', to: '/eau-guidelines' },
    { from: '/guidelines/endorsement', to: '/eau-guidelines/endorsements' },
    { from: '/guidelines/how-to-cite-the-eau-guidelines', to: '/eau-guidelines/citing-usage-republication' },
    { from: '/guidelines/ordering-the-eau-guidelines', to: '/eau-guidelines/ordering-the-eau-guidelines' },
    { from: '/guidelines/policies-and-methodological-documents', to: '/eau-guidelines/methodology-policies' },
    { from: '/guidelines/urology-cheat-sheets', to: '/eau-guidelines/urology-cheat-sheets' },
    { from: '/guidelines/usage-and-republication', to: '/eau-guidelines/citing-usage-republication' },
    { from: '/guidelines/usage-and-republication/eau-guidelines-infographics', to: '/guidelines' },
    { from: '/historia28', to: '/news/eau-history-office-translates-and-publishes-newly-discovered-vesalius-consilium' },
    { from: '/international-relations', to: '/international-relations' },
    { from: '/international-relations/about-us', to: '/international-relations' },
    { from: '/international-relations/eau-support-fund', to: '/international-relations/eau-support-fund' },
    { from: '/international-relations/exchange-programmes', to: '/education/exchange-programmes' },
    { from: '/international-relations/global-philanthropic-committee', to: '/international-relations/global-philanthropic-committee' },
    { from: '/international-relations/international-urological-associations', to: '/international-relations' },
    { from: '/international-relations/international-urological-associations/en-bloc', to: '/international-relations/en-bloc' },
    { from: '/international-relations/international-urological-associations/update-your-details', to: '/international-relations/update-your-details' },
    { from: '/international-relations/news', to: '/international-relations' },
    { from: '/links', to: '/' },
    { from: '/masterclass-content-on-urosource', to: '/education-events/events?topic=&type=masterclass&location=&page=1' },
    { from: '/membership', to: '/about/why-join-the-eau' },
    { from: '/membership/become-a-member', to: '/about/why-join-the-eau' },
    { from: '/membership/benefits', to: '/about/why-join-the-eau' },
    { from: '/membership/contribute', to: '/about/why-join-the-eau' },
    { from: '/membership/eau-in-your-country', to: '/about/why-join-the-eau' },
    { from: '/membership/meet', to: '/about/why-join-the-eau' },
    { from: '/membership/types', to: '/about/why-join-the-eau/membership-types' },
    { from: '/my-eau', to: 'https://myeau.uroweb.org/myeau/login?return_to=/myeau' },
    { from: '/my-eau/comments', to: 'https://myeau.uroweb.org/myeau/login?return_to=/myeau' },
    { from: '/my-eau/curriculum', to: 'https://myeau.uroweb.org/myeau/login?return_to=/myeau' },
    { from: '/my-eau/personal-notes', to: 'https://myeau.uroweb.org/myeau/login?return_to=/myeau' },
    { from: '/my-eau/saves', to: 'https://myeau.uroweb.org/myeau/login?return_to=/myeau' },
    { from: '/page', to: '/' },
    { from: '/policy', to: '/offices/eau-policy-office/policy' },
    { from: '/policy/key-areas-of-interest', to: '/offices/eau-policy-office/policy' },
    { from: '/policy/what-we-do', to: '/offices/eau-policy-office/policy' },
    { from: '/por-que-ser-un-miembro-de-eau', to: '/about/why-join-the-eau' },
    { from: '/preparing-for-horizon-europe', to: '/' },
    { from: '/regional-meetings', to: '/education-events/events?topic=&type=regional-meeting&location=&page=1' },
    { from: '/research', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/apply-for-use-of-eau-rfs-castor-database', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/become-an-investigator', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/career', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/how-we-work', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/initiate-a-trial', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/projects', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/publications', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/research', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/research/seeding-grant-application', to: '/offices/eau-foundation-for-urological-research' },
    { from: '/robotic-host-centre-signup', to: '/eau-robotic-urology-section-erus' },
    { from: '/section/erus', to: '/sections/eau-robotic-urology-section' },
    { from: '/section/esau', to: '/sections/eau-section-of-andrological-urology' },
    { from: '/section/esffu', to: '/sections/eau-section-of-female-functional-urology' },
    { from: '/section/esgurs', to: '/sections/eau-section-of-genitourinary-reconstructive-surgeons' },
    { from: '/section/esiu', to: '/sections/eau-section-of-infections-in-urology' },
    { from: '/section/esou', to: '/sections/eau-section-of-oncological-urology' },
    { from: '/section/estu', to: '/sections/eau-section-of-transplantation-urology' },
    { from: '/section/esui', to: '/sections/eau-section-of-urological-imaging' },
    { from: '/section/esuo', to: '/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/section/esup', to: '/sections/eau-section-of-uropathology' },
    { from: '/section/esur', to: '/sections/eau-section-of-urological-research' },
    { from: '/section/esut', to: '/sections/eau-section-of-uro-technology' },
    { from: '/section/eulis', to: '/sections/eau-section-of-urolithiasis' },
    { from: '/section/ewpu', to: '/sections/eau-working-group-on-paediatric-urology' },
    { from: '/section-meetings', to: '/education-events/events?topic=&type=section-meeting&location=&page=1' },
    { from: '/sections', to: '/offices/eau-section-office' },
    { from: '/the-european-cancer-plan', to: '/tags/european-cancer-plan' },
    { from: '/urosource', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/buy-access', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/cem15', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/erus15', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/erus16', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/erus17', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/esou16', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/esou17', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/eurep16', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/eurep17', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/live-surgery', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/pfizer', to: 'https://urosource.uroweb.org/' },
    { from: '/urosource/whats-urosource', to: 'https://urosource.uroweb.org/' },
    { from: '/vacancies/congress-project-coordinator', to: '/about/vacancies' },
    { from: '/vacancies/congress-support-officer', to: '/about/vacancies/congress-support-officer' },
    { from: '/vacancies/e-mail-marketeer', to: '/about/vacancies/e-mail-marketeer' },
    { from: '/vacancies/patient-information-coordinator', to: '/about/vacancies' },
    { from: '/vacancies/project-coordinator-education-training-2', to: '/about/vacancies' },
    { from: '/videos/a-look-at-erus-2014', to: '/sections/eau-robotic-urology-section' },
    { from: '/videos/a-look-at-erus-2014-2', to: '/sections/eau-robotic-urology-section' },
    { from: '/videos/eau15-esuerus-hands-on-training-in-robotic-surgery', to: '/sections/eau-robotic-urology-section' },
    { from: '/videos/erus15-looking-back-at-bilbao', to: '/sections/eau-robotic-urology-section' },
    { from: '/videos/erus-2015', to: '/sections/eau-robotic-urology-section' },
    { from: '/whats-next-a-patient-with-diabetes-obesity-and-lower-urinary-tract-symptoms-luts', to: '/education-events/education?topic=&type=e-course&page=1' },
    { from: '/individual-guidelines/non-oncology-guidelines', to: 'https://uroweb.org/guidelines' },
    { from: '/individual-guidelines/oncology-guidelines', to: 'https://uroweb.org/guidelines' },
    { from: '/section/erus/education', to: 'https://uroweb.org/sections/eau-robotic-urology-section' },
    { from: '/section/esau/information', to: 'https://uroweb.org/sections/eau-section-of-andrological-urology' },
    { from: '/section/esffu/information', to: 'https://uroweb.org/sections/eau-section-of-female-functional-urology' },
    { from: '/section/esgurs/information', to: 'https://uroweb.org/sections/eau-section-of-genitourinary-reconstructive-surgeons' },
    { from: '/section/esiu/information', to: 'https://uroweb.org/sections/eau-section-of-infections-in-urology' },
    { from: '/section/esou/information', to: 'https://uroweb.org/sections/eau-section-of-oncological-urology' },
    { from: '/section/estu/information', to: 'https://uroweb.org/sections/eau-section-of-transplantation-urology' },
    { from: '/section/esui/information', to: 'https://uroweb.org/sections/eau-section-of-urological-imaging' },
    { from: '/section/esuo/information', to: 'https://uroweb.org/sections/eau-section-of-outpatient-and-office-urology' },
    { from: '/section/esup/information', to: 'https://uroweb.org/sections/eau-section-of-uropathology' },
    { from: '/section/esur/information', to: 'https://uroweb.org/sections/eau-section-of-urological-research' },
    { from: '/section/esut/information', to: 'https://uroweb.org/sections/eau-section-of-uro-technology' },
    { from: '/section/eulis/information', to: 'https://uroweb.org/sections/eau-section-of-urolithiasis' },
    { from: '/section/ewpu/information', to: 'https://uroweb.org/sections/eau-working-group-on-paediatric-urology' },
    { from: '/section/erus/board', to: 'https://uroweb.org/sections/eau-robotic-urology-section' },
    { from: '/education/young-urologists-office-yuo/yau/mens-health-group', to: 'https://uroweb.org/offices/young-academic-urologists' },
    { from: '/education/scholarship/programmes/fellowship', to: 'https://uroweb.org/education/scholarships/fellowship' },
    { from: '/scientific-experts-support-addition-of-life-saving-prostate-cancer-early-detection-programmes', to: 'https://uroweb.org/news/scientific-experts-support-addition-of-life-saving-prostate-cancer-early-detection-programmes' },
    { from: '/guideline/renalcellcarcinoma', to: 'https://uroweb.org/guidelines/renal-cell-carcinoma' },
    { from: '/education/online-education/surgical-education/laparoscopy', to: 'https://uroweb.org/education-events/laparoscopy' },
    { from: '/education/live-events/hands-on-training-courses', to: 'https://uroweb.org/education' },
    { from: '/webinars', to: 'https://uroweb.org/education-events/events' },
    { from: '/section/erus/erus-robotic-certified-host-centers', to: 'https://uroweb.org/sections/eau-robotic-urology-section' },
    { from: '/education/young-urologists-office-yuo/yau/get-involved-2', to: 'https://uroweb.org/offices/young-urologists-office' },
    { from: '/education/online-education/e-courses', to: 'https://uroweb.org/education-events/education' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urological-infections-2021.pdf', to: 'https://uroweb.org/guidelines/urological-infections' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urological-infections-2020.pdf', to: 'https://uroweb.org/guidelines/urological-infections' },
    { from: '/wp-content/uploads/19-Urological-infections_LR2.pdf', to: 'https://uroweb.org/guidelines/urological-infections' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Non-Neurogenic-Male-LUTS-incl.-BPO-2020.pdf', to: 'https://uroweb.org/guidelines/management-of-non-neurogenic-male-luts' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-the-Management-of-Non-neurogenic-Male-LUTS-2018-large-text.pdf', to: 'https://uroweb.org/guidelines/management-of-non-neurogenic-male-luts' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urolithiasis-2019.pdf', to: 'https://uroweb.org/guidelines/urolithiasis' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urolithiasis-2021.pdf', to: 'https://uroweb.org/guidelines/urolithiasis' },
    { from: '/wp-content/uploads/EAU-Pocket-Guidelines-on-Urological-Infections-2020.pdf', to: 'https://uroweb.org/guidelines/urological-infections' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Sexual-and-Reproductive-Health-2021.pdf', to: 'https://uroweb.org/guidelines/sexual-and-reproductive-health' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Sexual-and-Reproductive-Health-2020.pdf', to: 'https://uroweb.org/guidelines/sexual-and-reproductive-health' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Testicular-Cancer-2019-1.pdf', to: 'https://uroweb.org/guidelines/testicular-cancer' },
    { from: '/wp-content/uploads/EAU-ESPU-Guidelines-on-Paediatric-Urology-2020.pdf', to: 'https://uroweb.org/guidelines/paediatric-urology' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urological-infections-2019.pdf', to: 'https://uroweb.org/guidelines/urological-infections' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urological-Infections-2018-large-text.pdf', to: 'https://uroweb.org/guidelines/urological-infections' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urolithiasis-2018-large-text.pdf', to: 'https://uroweb.org/guidelines/urolithiasis' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Management-of-Non-Neurogenic-Male-LUTS-2021.pdf', to: 'https://uroweb.org/guidelines/management-of-non-neurogenic-male-luts' },
    { from: '/wp-content/uploads/EAU-EANM-ESUR-ESTRO-SIOG-Guidelines-on-Prostate-Cancer-2019-1.pdf', to: 'https://uroweb.org/guidelines/prostate-cancer' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Upper-Urinary-Tract-Urothelial-Carcinoma-2020.pdf', to: 'https://uroweb.org/guidelines/upper-urinary-tract-urothelial-cell-carcinoma' },
    { from: '/wp-content/uploads/EAU-Guidelines-on-Urinary-Incontinence-2020.pdf', to: 'https://uroweb.org/guidelines/non-neurogenic-female-luts' },
    { from: '/wp-content/uploads/EAU-EANM-ESTRO_ESUR_ISUP_SIOG-Guidelines-on-Prostate-Cancer-2021.pdf', to: 'https://uroweb.org/guidelines/prostate-cancer' },
    { from: '/eau-section-of-andrological-urology-esau', to: 'https://uroweb.org/eau-sections/eau-section-of-andrological-urology-esau' },
    { from: '/eau-section-of-female-functional-urology', to: 'https://uroweb.org/eau-sections/eau-section-of-female-functional-urology-esffu' },
    { from: '/eau-section-of-infections-in-urology-esiu', to: 'https://uroweb.org/eau-sections/eau-section-of-infections-in-urology-esiu' },
    { from: '/eau-section-of-oncological-urology', to: 'https://uroweb.org/eau-sections/eau-section-of-oncological-urology-esou' },
    { from: '/eau-section-of-urological-imaging-esui', to: 'https://uroweb.org/eau-sections/eau-section-of-urological-imaging-esui' },
    { from: '/eau-section-of-urological-research-esur', to: 'https://uroweb.org/eau-sections/eau-section-of-urological-research-esur' },
    { from: '/eau-robotic-urology-section-erus', to: 'https://uroweb.org/eau-sections/eau-robotic-urology-section-erus' },
    { from: '/eau-section-of-uro-technology-esut', to: 'https://uroweb.org/eau-sections/eau-section-of-uro-technology-esut' },
    { from: '/eau-section-of-transplantation-urology-estu', to: 'https://uroweb.org/eau-sections/eau-section-of-transplantation-urology-estu' },
    { from: '/eau-section-of-urolithiasis-eulis', to: 'https://uroweb.org/eau-sections/eau-section-of-urolithiasis-eulis' },
    { from: '/eau-section-of-outpatient-and-office-urology-esuo', to: 'https://uroweb.org/eau-sections/eau-section-of-outpatient-and-office-urology-esuo' },
    { from: '/eau-working-group-on-paediatric-urology-ewpu', to: 'https://uroweb.org/eau-sections/eau-working-group-on-paediatric-urology-ewpu' },
    { from: '/masterclasses', to: 'https://uroweb.org/education-events/events?page=1&type=masterclass' },
    { from: '/science-publications/press-releases', to: 'https://uroweb.org/press-releases' },
    { from: '/EUT1', to: 'https://uroweb.org/the-first-edition-of-european-urology-today' },
    { from: '/eut1', to: 'https://uroweb.org/the-first-edition-of-european-urology-today' },
    { from: '/incubator', to: 'https://uroweb.org/eau-talent-incubator-programme' },
    { from: '/login', to: 'https://uroweb.org/myeau-offline-from-20-23-april' },
    { from: '/esu', to: 'https://uroweb.org/about/how-we-work/the-european-school-of-urology-esu' },
    { from: '/offices/yau-urotechnology-working-group', to: 'https://uroweb.org/offices/yau-working-group-urotechnology-digital-health' }
];

export default function({ route, redirect }) {
    // Prefix migrated news articles
    legacyNewsSlugs.forEach((slug) => {
        if (route.path === `/${slug}`) {
            return redirect(`/news/${slug}`);
        }
    });

    // Static from->to redirects
    staticRedirects.forEach((item) => {
        if (route.path === item.from || route.path === item.from + '/') {
            redirect(item.to);
        }
    });
}
