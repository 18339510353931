<template>
    <div class="navigation-submenu">
        <div class="navigation-submenu__container">
            <nav v-if="primaryItems && primaryItems.length" class="navigation-submenu__left">
                <uro-navigation-link
                    v-for="(item, index) in primaryItems"
                    :key="`primary${index}`"
                    :title="item.menuItemTitle"
                    :subtitle="item.menuItemDescription"
                    :icon="mapCmsIcon(item.menuItemIcon)"
                    :element="getItemElement(item)"
                    :is-sub="true"
                    v-bind="getAttributes(item)"
                />
            </nav>

            <nav v-if="secondaryItems && secondaryItems.length" class="navigation-submenu__right">
                <uro-navigation-link
                    v-for="(item, index) in secondaryItems"
                    :key="`secondary${index}`"
                    :title="item.menuItemTitle"
                    :subtitle="item.menuItemDescription"
                    :icon="mapCmsIcon(item.menuItemIcon)"
                    :element="getItemElement(item)"
                    :is-sub="true"
                    v-bind="getAttributes(item)"
                />
            </nav>
        </div>
    </div>
</template>

<script>
import cmsIcon from '~/mixins/cmsIcon.vue';
import uriMixin from '~/mixins/uri.vue';

export default {
    name: 'UroNavigationSubmenu',

    components: {
        'uro-navigation-link': () => import('../navigation-link/navigation-link.vue') // Dyanmic import due to circular ref.
    },

    mixins: [cmsIcon, uriMixin],

    props: {
        primaryItems: {
            type: Array,
            required: false,
            default: null
        },
        secondaryItems: {
            type: Array,
            required: false,
            default: null
        }
    },

    methods: {
        getUri(item) {
            if (item.internalLink && item.internalLink.length) {
                return `/${item.internalLink[0]?.uri}`;
            }

            return item.externalLink;
        },

        getItemElement(item) {
            return this.checkIsExternal(this.getUri(item)) ? 'a' : 'nuxt-link';
        },

        getAttributes(item) {
            if (this.getItemElement(item) === 'a') {
                return {
                    href: this.getUri(item)
                };
            } else {
                return {
                    to: this.getUri(item) || '/'
                };
            }
        }
    }
};
</script>

<style lang="less" src="./navigation-submenu.less" />
