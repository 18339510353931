export default () => ({
    searchQuery: '',
    isSearching: false,
    totalHighlights: null,
    chapters: [],
    currentHighlightNumber: 0,
    currentGuidelineId: null,
    currentChapterSlug: '',
    currentChapterContent: '',
});
