import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import onError from '~/modules/bugsnag/onError.js';

// Parse lodash template options into usable JS
const options = JSON.parse('{"browserApiKey":"04c5a34250a2d1834675f91bbcd7639e","serverApiKey":"04c5a34250a2d1834675f91bbcd7639e","releaseStage":"production","enabledReleaseStages":["staging","production"]}');

Bugsnag.start({
    apiKey: options.browserApiKey,
    plugins: [new BugsnagPluginVue(Vue)],
    releaseStage: options.releaseStage,
    enabledReleaseStages: options.enabledReleaseStages,
    onError
});
